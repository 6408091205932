import { apiClient } from "./axiosConfig";
import { apiPaths } from "./config";

export const arcGISLogin = async () => {
    const token: any = localStorage.getItem("token_access_imageryst");
  
    const HEADERS = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  
    return await apiClient.get(`${apiPaths.arcgis}/login`, HEADERS);
  }
  
  export const getArcGISToken = async () => {
  
    const token: any = localStorage.getItem("token_access_imageryst");
  
    const HEADERS = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  
    return await apiClient.get(`${apiPaths.arcgis}/protected`, HEADERS);
  }
  
  
  export const publishArcGIS = async (idProject: number, datasetIds: number[]) => {
  
    const token: any = localStorage.getItem("token_access_imageryst");
  
    const HEADERS = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  
    const formData = new FormData();
    formData.append("id_project", `${idProject}`);
    formData.append("datasets", JSON.stringify(datasetIds));
  
    return await apiClient.post(`${apiPaths.arcgis}/publish`, formData, HEADERS);
  }
  