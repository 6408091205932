import React from "react";
import { Redirect, Route } from "react-router-dom";
import { localStorageKeys } from "../services/config";
import { navigation } from "../utils/navigation";

function PrivateRoute({ component: Component, ...rest }) {
  const isUserLogged = localStorage.getItem(localStorageKeys.ACCESS_TOKEN);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isUserLogged === null) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: navigation.login, state: { from: props.location } }} />;
        }

        // authorized so return component
        return <Component {...props} />;
      }}
    />
  );
}

export default PrivateRoute;
