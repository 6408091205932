import { TileLayer } from '@deck.gl/geo-layers';
import { BitmapLayer } from '@deck.gl/layers';
import { bbox as turfBbox } from '@turf/turf';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Modal } from '@mui/material';
import LateralMenu from '../../components/lateralMenu/lateralMenu';
import Map from '../../components/map/map';
import MenuMap from '../../components/menuMap/menuMap';
import CustomSnackbar from '../../components/snackbar';
import { fetchData } from '../../utils/requests';
import { fetchKPISData } from '../../services/datasets';
import { useTranslation } from 'react-i18next';
import { TILE_SERVER } from '../../config/env';
import { getNumberPages, getProjectByUser } from '../../services/projects';
import { navigation } from '../../utils/navigation';
import { useAppSlice } from '../../slice';
import { baseAltitudeGroups, baseRadiationGroups } from './utils/utils';
import { getMVTLayer, getMVTName } from './utils/mvtLayerUtils';
import { dashboardProjects } from '../../slice/selectors';
import { useDashboardSlice } from '../../slice/dashboard';
import './style.scss';
import { BASEMAP_OPTIONS } from '../../constants';

function Dashboard() {
  const [dataResultsMap, setDataResultsMap] = useState({});
  const [snackbarProps, setSnackbarProps] = useState({
    message: '',
    severity: '',
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [listDataset, setListDataset] = useState({});
  const [optionSelectKPI, setOptionSelectKPI] = useState('');
  const [initialMapBbox, setInitialMapBbox] = useState({
    bbox: null,
    zoom: null,
  });
  const [aoi, setAoi] = useState(null);
  const [area, setArea] = useState(0);
  const [numberPages, setNumberPages] = useState(1);
  const [searched, setSearched] = useState(
    new URLSearchParams(window.location.search).get('projectName'),
  );
  const [page, setPage] = useState(1);
  const [layersArray, setLayersArray] = useState([]);

  const dispatch = useDispatch();
  const { actions } = useAppSlice();
  const { projectList, isLoading } = useSelector(dashboardProjects);
  const { actions: dashboardActions } = useDashboardSlice();
  const { t } = useTranslation();
  const lateralMenuRef = useRef(null);
  const history = useHistory();

  const handleChangeSelect = e => {
    const { value } = e.target;
    setOptionSelectKPI(value);
  };

  useEffect(() => {
    const getKpis = async () => {
      const projectDataset = optionSelectKPI.split(':');
      if (listDataset[parseFloat(projectDataset[1])]) {
        await fetchKPISData(projectDataset[1], projectDataset[0])
          .then(function (response) {
            if (response.status === 200) {
              setDataResultsMap(previous => {
                return {
                  ...previous,
                  [projectDataset[0]]: response.data,
                };
              });
            }
          })
          .catch(function (err) {
            setSnackbarProps({
              message: err.response?.data
                ? err.response.data.message
                : t('common.genericErrorMessage'),
              severity: 'error',
            });
            setOpenSnackbar(true);
          });
      }
    };
    optionSelectKPI && getKpis();
  }, [optionSelectKPI]);

  useEffect(() => {
    if (Object.keys(listDataset).length > 0) {
      const [idProject, datasets] = Object.entries(listDataset)[0];
      const idDataset = datasets[0].id_dataset;
      setOptionSelectKPI(`${idDataset}:${idProject}`);
    } else {
      setOptionSelectKPI('');
    }
  }, [listDataset]);

  const handleChangeTransparency = async (
    event,
    id_project,
    id_dataset,
    name_attribute,
    subimage,
  ) => {
    event.stopPropagation();

    listDataset[id_project].forEach(dataset => {
      if (dataset.id_dataset === id_dataset) {
        dataset.transparency = event.target.value;
      }
    });

    const layerId = getMVTName(name_attribute, id_dataset, id_project);

    if (layerId !== '') {
      const layerNoSelect = layersArray.filter(layer => layer?.id !== layerId);

      setLayersArray(layerNoSelect);

      const layer = await getMVTLayer({
        name_attribute,
        id_project,
        id_dataset,
        transparency: event.target.value,
        subimage,
        dataResults: dataResultsMap,
      });

      const checkedLayers = [];
      listDataset[id_project].forEach(dataset => {
        if (dataset.id_dataset === id_dataset && dataset.checked) {
          checkedLayers.push(layer);
        }
      });
      setLayersArray(oldArray => [...oldArray, ...checkedLayers]);
    }
  };

  const handleChangeTransparencyXyz = async (event, id_project, id_dataset) => {
    event.stopPropagation();

    const layerId = `xyz${id_dataset}_${id_project}`;

    if (layerId !== '') {
      const layerNoSelect = layersArray.filter(layer => layer.id !== layerId);

      setLayersArray(layerNoSelect);

      if (!layersArray.some(layer => layer.id === layerId)) {
        // const layer = await getXyzLayer(id_project, id_dataset);
        const layer = await getCogLayer(id_project, id_dataset);

        if (layer) setLayersArray(oldArray => [layer, ...oldArray]);
      }
    }
  };

  const fetchMVTLayer = async (
    name_attribute,
    id_project,
    id_dataset,
    transparency,
    subimage,
  ) => {
    const project = projectList.find(p => p.id_project === id_project);
    const area = project.area;
    setArea(area);
    if (
      name_attribute === 'Radiation' ||
      name_attribute === 'Altitude' ||
      name_attribute === 'Vegetation height' ||
      name_attribute === 'Height'
    ) {
      const optionalParameters = {
        kpisGroups:
          name_attribute === 'Radiation'
            ? baseRadiationGroups
            : baseAltitudeGroups,
      };

      await fetchKPISData(id_project, id_dataset)
        .then(function (response) {
          if (response.status === 200) {
            optionalParameters.kpisGroups = response.data.groups;
            setDataResultsMap(previous => {
              return {
                ...previous,
                [id_dataset]: response.data,
              };
            });
          } else {
            setDataResultsMap(previous => {
              return {
                ...previous,
                [id_dataset]: {
                  ...previous[id_dataset],
                  chart: {},
                  groups: optionalParameters.kpisGroups,
                },
              };
            });
          }
        })
        .catch(function (err) {
          setSnackbarProps({
            message: err.response?.data
              ? err.response.data.message
              : t('common.genericErrorMessage'),
            severity: 'error',
          });
          setOpenSnackbar(true);
        });

      return getMVTLayer({
        name_attribute,
        id_project,
        id_dataset,
        transparency,
        optionalParameters,
        subimage,
        dataResults: dataResultsMap,
      });
    } else {
      return getMVTLayer({
        name_attribute,
        id_project,
        id_dataset,
        transparency,
        subimage,
        dataResults: dataResultsMap,
      });
    }
  };

  const getCogLayer = async (idProject, idDataset) => {
    const cogParams = {
      "id_dataset": idDataset,
      "id_project": idProject
    }

    const parametersString = Object.entries(cogParams).map(([paramName, paramValue]) => {
      return `${paramName}=${paramValue}`
    }).join("&")

    const tileUrl = `${TILE_SERVER}/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?${parametersString}`
    const token = localStorage.getItem("token_access_imageryst");

    return new TileLayer({
      id: `xyz${idDataset}_${idProject}`,
      data: tileUrl,
      minZoom: 13,
      maxZoom: 19,
      loadOptions: {
        fetch: {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }
      },
      renderSubLayers: props => {
        const {
          bbox: { west, south, east, north }
        } = props.tile;

        return new BitmapLayer({
          image: props.data,
          bounds: [west, south, east, north]
        });
      }
    })
  }

  const handleChangeCheckLayer = async (
    event,
    id_project,
    id_dataset,
    name_attribute,
    transparency,
    subimage
  ) => {
    event.stopPropagation();
    const dataSetSelect = listDataset[id_project].find(
      p => p.id_dataset === id_dataset,
    );
    dataSetSelect.checked = event.target.checked;
    setListDataset(listDataset);

    const xyzLayerId = `xyz${id_dataset}_${id_project}`;

    setOptionSelectKPI(`${id_dataset}:${id_project}`);
    if (event.target.checked) {
      const layer = await fetchMVTLayer(
        name_attribute,
        id_project,
        id_dataset,
        transparency,
        subimage
      );
      const xyzLayer = await getCogLayer(id_project, id_dataset);

      if (layer !== undefined) {
        if (xyzLayer) {
          setLayersArray(oldArray => [xyzLayer, ...oldArray, layer]);
        } else {
          setLayersArray(oldArray => [...oldArray, layer]);
        }
      }
    } else {
      const layerId = getMVTName(name_attribute, id_dataset, id_project);

      if (layerId !== '') {
        const layerNoSelect = layersArray.filter(
          layer => layer.id !== layerId && layer.id !== xyzLayerId,
        );

        setLayersArray(layerNoSelect);
      }
    }
    if (listDataset[id_project])
      listDataset[id_project].forEach(dataset => {
        if (dataset.id_dataset === id_dataset) {
          dataset.checked = event.target.checked;
        }
      });
  };

  const getProjectList = async () => {
    dispatch(dashboardActions.setIsLoading(true));
    const projectName = searched ? searched : null;
    await getNumberPages(projectName, 'Completed')
      .then(function (response) {
        if (response.status === 200) {
          setNumberPages(response.data.number_of_pages);
        }
      })
      .catch(function (err) {
        setSnackbarProps({
          message: err.response?.data
            ? err.response.data.message
            : t('common.genericErrorMessage'),
          severity: 'error',
        });
        setOpenSnackbar(true);
      });
    await getProjectByUser(
      projectName ? 1 : page,
      projectName,
      null,
      null,
      'Completed',
    )
      .then(function (response) {
        if (response.status === 200) {
          const listProjectCheck = Object.values(response.data.message).map(
            p => ({
              ...p,
              checked: false,
              content: '',
              date: '',
              display: true,
              transparency: 75,
            }),
          );

          //ordenar por last_executed
          listProjectCheck.sort((x, y) => {
            const xDate = new Date(
              x.creation_date.split('-')[1] +
              '-' +
              x.creation_date.split('-')[0] +
              '-' +
              x.creation_date.split('-')[2],
            );
            const yDate = new Date(
              y.creation_date.split('-')[1] +
              '-' +
              y.creation_date.split('-')[0] +
              '-' +
              y.creation_date.split('-')[2],
            );
            return yDate.getTime() - xDate.getTime();
          });
          dispatch(dashboardActions.fetchCompletedProjects(listProjectCheck));
        }
      })
      .catch(function (err) {
        if (err.response.status !== 404) {
          dispatch(dashboardActions.fetchCompletedProjects([]));
          setSnackbarProps({
            message: err.response?.data
              ? err.response.data.message
              : t('common.genericErrorMessage'),
            severity: 'error',
          });
          setOpenSnackbar(true);
        }
      })
      .finally(() => dispatch(dashboardActions.setIsLoading(false)));
  };

  useEffect(() => {
    getProjectList();
  }, []);

  const cleanLayers = id_project => {
    const newLayersArray = layersArray.filter(layer => {
      return Object.keys(BASEMAP_OPTIONS).includes(layer.id)
    })

    setLayersArray(newLayersArray);
    delete listDataset[id_project];
    setDataResultsMap({});
  };

  const handleChangeProject = async (
    event,
    id_project,
    index,
    name_project,
  ) => {
    const project = projectList.find(p => p.id_project === id_project);
    const area = project.area;

    setAoi(project.aoi);

    setArea(area);

    cleanLayers(id_project);

    projectList.map((project, key) => {
      if (key === index) {
        dispatch(
          dashboardActions.editProject({
            ...project,
            checked: event.target.checked,
          }),
        );
      } else {
        dispatch(dashboardActions.editProject({ ...project, checked: false }));
        setDataResultsMap({});
      }
    });

    const id_user = localStorage.getItem('id_user');

    if (event.target.checked) {
      await fetchData(`${id_user}/${id_project}`, 'GET_DATASET')
        .then(function (response) {
          if (response.status === 200) {
            const listDatasetCheck = Object.values(response.data.message).map(
              p => ({
                ...p,
                checked: false,
                display: true,
                project_name: name_project,
                transparency: 75,
              }),
            );

            if (response.data.message.length > 0) {
              let features = [];
              response.data.message.forEach(item => {
                features = [
                  ...features,
                  ...item.input_alg_parameters.mask.features,
                ];
              });
              try {
                setInitialMapBbox({
                  bbox: turfBbox({
                    type: 'FeatureCollection',
                    features: features,
                  }),
                  zoom: listDatasetCheck[0]?.default_zoom,
                });
              } catch (error) {
                setSnackbarProps({
                  message: error.response.data.message,
                  severity: 'error',
                });
                setOpenSnackbar(true);
              }
            }

            setListDataset(() => {
              return {
                [id_project]: listDatasetCheck,
              };
            });
            dispatch(actions.setOpenMapPanel(true));
          }
        })
        .catch(function (err) {
          setSnackbarProps({
            message: err.response?.data
              ? err.response.data.message
              : t('common.genericErrorMessage'),
            severity: 'error',
          });
          setOpenSnackbar(true);
        });
    } else {
      cleanLayers(id_project);
    }
  };

  useEffect(() => {
    // Scroll to the top of the component when content changes
    if (lateralMenuRef?.current) lateralMenuRef.current.scrollTo(0, 0);
  }, [page]);

  const handleChangePagination = async (event, value) => {
    dispatch(dashboardActions.setIsLoading(true));
    await getProjectByUser(value, null, null, null, 'Completed')
      .then(function (response) {
        if (response.status === 200) {
          const listProjectCheck = Object.values(response.data.message).map(
            p => ({
              ...p,
              checked: false,
              content: '',
              date: '',
              display: true,
              transparency: 75,
            }),
          );

          //ordenar por last_executed
          listProjectCheck.sort((x, y) => {
            const xDate = new Date(
              x.creation_date.split('-')[1] +
              '-' +
              x.creation_date.split('-')[0] +
              '-' +
              x.creation_date.split('-')[2],
            );
            const yDate = new Date(
              y.creation_date.split('-')[1] +
              '-' +
              y.creation_date.split('-')[0] +
              '-' +
              y.creation_date.split('-')[2],
            );
            return yDate.getTime() - xDate.getTime();
          });
          dispatch(dashboardActions.fetchCompletedProjects(listProjectCheck));
        }
      })
      .catch(function (err) {
        setSnackbarProps({
          message: err.response?.data
            ? err.response.data.message
            : t('common.genericErrorMessage'),
          severity: 'error',
        });
        setOpenSnackbar(true);
      })
      .finally(() => dispatch(dashboardActions.setIsLoading(false)));
    setPage(value);
  };

  const handleChangeSearchDataset = () => {
    const projectsToClean = projectList.filter(project => {
      return project.checked;
    });

    for (const project of projectsToClean) {
      cleanLayers(project.id_project);
    }

    history.push({
      pathname: navigation.dashboardResults,
      search: searched ? `?projectName=${searched}` : '',
    });
    getProjectList();
    if (lateralMenuRef?.current) lateralMenuRef.current.scrollTo(0, 0);
  };

  const requestSearch = event => {
    const searchedVal = event.target.value;
    setSearched(searchedVal);
  };

  const updateDataset = (idProject, updatedDataset) => {
    const newListDataset = listDataset[idProject].map(currentDataset => {
      if (currentDataset.id_dataset == updatedDataset.id_dataset) {
        return { ...currentDataset, ...updatedDataset };
      }
      return currentDataset;
    });
    setListDataset({ ...listDataset, [idProject]: newListDataset });
  };

  return (
    <div className="container">
      <div className="container_map">
        <Modal
          open={isLoading}
          onClose={() => dispatch(dashboardActions.setIsLoading(false))}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="containerLoader">
            <CircularProgress
              sx={{
                color: '#7024D1',
              }}
              size={70}
            />
          </div>
        </Modal>
        <LateralMenu
          handleChangeProject={handleChangeProject}
          numberPages={numberPages}
          page={page}
          handleChangePagination={handleChangePagination}
          handleSearch={handleChangeSearchDataset}
          searchValue={searched}
          onChangeSearchValue={requestSearch}
          lateralMenuRef={lateralMenuRef}
        />
        <Map
          layersArray={layersArray}
          initialMapBbox={initialMapBbox}
          setLayersArray={setLayersArray}
          aoi={aoi}
        />
        <MenuMap
          listDataset={listDataset}
          updateDataset={updateDataset}
          handleChangeCheckLayer={handleChangeCheckLayer}
          dataResultsMap={dataResultsMap}
          handleChangeSelect={handleChangeSelect}
          optionSelectKPI={optionSelectKPI}
          handleChangeTransparency={handleChangeTransparency}
          handleChangeTransparencyXyz={handleChangeTransparencyXyz}
          area={area}
          setSnackbarProps={setSnackbarProps}
          setOpenSnackbar={setOpenSnackbar}
        />
      </div>
      <CustomSnackbar
        snackbarProps={snackbarProps}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
    </div>
  );
}

export default Dashboard;
