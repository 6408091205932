import { TileLayer, _WMSLayer as WMSLayer } from '@deck.gl/geo-layers';
import { BitmapLayer } from '@deck.gl/layers';
import dayjs from 'dayjs';

export const initialOption = {
  asset: 4,
  attribute: null,
  dateStart: dayjs().subtract(60, 'day'),
  dateEnd: dayjs(),
  selectLocation: '',
  selectImageType: '',
  selectSubImageType: {},
  output: '',
  cloudCoverage: 20,
  minArea: 25,
  spatialScale: 1,
};

export const ATTRIBUTE_EQUIVALENCE = {
  1: 'Height',
  2: 'Slope (Pitch)',
  3: 'Aspect',
  4: 'Radiation',
  5: 'Temperature',
  6: 'Watersheds',
  7: 'Altitude',
  8: 'Humidity',
  9: 'None',
  10: 'Vegetation health',
  11: 'Vegetation growth',
  12: 'CO2',
  13: 'Area',
  14: 'Building Footprints',
  15: 'Solar Panels',
  16: 'Pools',
  17: 'Roads',
  18: 'Rooftop Type',
  19: 'Solar Panels Plants',
  20: 'Solar Trackers Plants',
  21: 'Wind Turbines',
  22: 'Powerblocks',
  23: 'Roads (Solar Plants)',
  24: 'Segmentation',
  25: 'Contour Lines',
  26: 'Vegetation height',
  27: 'Burned areas',
  28: 'Water bodies',
  29: 'Monitoring',
  30: 'Night time lights',
  31: 'Vehicles',
  32: 'Earthworks Monitoring',
  33: 'High Tension Towers',
  34: 'Medium Tension Towers',
  35: 'Trenches',
  36: 'Pipes',
  37: 'Individual Trees',
};

export const ASSET_EQUIVALENCE = {
  2: 'Buildings',
  4: 'Parcels',
  5: 'Vegetation units',
  6: 'Extraction areas',
  7: 'Collection areas',
};

export const ATTRIBUTE_ZOOM = {
  'Solar Panels': 17,
  Roads: 19,
  'Solar Panels Plants': 17,
  'Solar Trackers Plants': 17,
  'Wind Turbines': 17,
  Powerblocks: 17,
  'Roads (Solar Plants)': 19,
  Vehicles: 19,
};

export const INPUT_IMAGES_WITH_CLOUD_COVERAGE = ['Copernicus', 'Planet'];
export const INPUT_IMAGES_WITH_START_DATE = ['Copernicus', 'Planet', 'NASA'];
export const INPUT_IMAGES_WITH_END_DATE = ['Copernicus', 'Planet'];
export const ATTRIBUTES_WITH_AREA = [
  'Watersheds',
  'Segmentation',
  'Building Footprints',
];
export const ATTRIBUTES_WITH_SPATIAL_SCALE = ['Contour Lines'];

export const MAX_ATTRIBUTE_AREA = {
  Watersheds: 500,
  Segmentation: 10000,
  'Building Footprints': 2000,
};

export const ICON_MAPPING = {
  marker: {
    x: 0,
    y: 0,
    width: 500,
    height: 500,
    mask: true,
  },
};

// Viewport settings
export const INITIAL_VIEW_STATE = {
  longitude: -3.683556505437764,
  latitude: 40.41494577944909,
  zoom: 14,
  pitch: 0,
  bearing: 0,
};

export const IMAGE_TYPES = {
  dsm: 'User DSM',
  dtm: 'User DTM',
  satellite: 'Satellite',
  basemap: 'Basemap',
};

export const BASEMAP_OPTIONS = {
  bing_layer: {
    layerName: 'Bing (World)',
    layer: new TileLayer({
      id: 'bing_layer',
      data: 'https://atlas.microsoft.com/map/tile?api-version=2.0&tilesetId=microsoft.imagery&zoom={z}&x={x}&y={y}&subscription-key=2jBK0pEwDDoHxvpXOXwvDiEcnsu0X2LgBSA424KjeGLsJxbR1vbIJQQJ99AFAC5RqLJWu3RuAAAgAZMPAkdU',
      tileSize: 256,
      minZoom: 0,
      maxZoom: 22,
      attribution: '© Microsoft',
      renderSubLayers: props => {
        const { boundingBox } = props.tile;
        return new BitmapLayer({
          image: props.data,
          bounds: [
            boundingBox[0][0],
            boundingBox[0][1],
            boundingBox[1][0],
            boundingBox[1][1],
          ],
        });
      },
    }),
  },
  esri_layer: {
    layerName: 'Esri (World)',
    layer: new TileLayer({
      id: 'esri_layer',
      data: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png',
      tileSize: 256,
      minZoom: 0,
      maxZoom: 22,
      attribution: '© ArcGIS',
      renderSubLayers: props => {
        const { boundingBox } = props.tile;
        return new BitmapLayer({
          image: props.data,
          bounds: [
            boundingBox[0][0],
            boundingBox[0][1],
            boundingBox[1][0],
            boundingBox[1][1],
          ],
        });
      },
    }),
  },
  google_layer: {
    layerName: 'Google (World)',
    layer: new TileLayer({
      id: 'google_layer',
      data: 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&s=Ga',
      tileSize: 256,
      minZoom: 0,
      maxZoom: 22,
      attribution: '© Google',
      renderSubLayers: props => {
        const { boundingBox } = props.tile;
        return new BitmapLayer({
          image: props.data,
          bounds: [
            boundingBox[0][0],
            boundingBox[0][1],
            boundingBox[1][0],
            boundingBox[1][1],
          ],
        });
      },
    }),
  },
  mapbox_layer: {
    layerName: 'Mapbox (World)',
    layer: null,
  },
  pnoa_layer: {
    layerName: 'PNOA (Spain)',
    layer: new WMSLayer({
      id: 'pnoa_layer',
      data: 'https://www.ign.es/wms-inspire/pnoa-ma',
      layers: ['OI.OrthoimageCoverage'],
      serviceType: 'wms',
    }),
  },
};
