import { Button, Menu, MenuItem, Tooltip } from '@mui/material';
import { Checkbox, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import SearchInputCustom from '../../../components/ui/searchInput';
import ButtonCustom from '../../../components/ui/button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SearchIcon from '@mui/icons-material/Search';
import { ProjectMessage } from '../../../models/project';
import { useTranslation } from 'react-i18next';
import { ProjectFiltersState } from '..';

export interface ProjectFiltersProps {
  projects: ProjectMessage[];
  handleSearchProject: (filters?: ProjectFiltersState) => void;
  handleSelectAllProjects: (isChecked: boolean) => void;
  setOpenModalDelete: (value: boolean) => void;
  selectedProjects: number[];
  filters: ProjectFiltersState;
  setFilters: (filters: ProjectFiltersState) => void;
}

const ProjectFilters = ({
  projects,
  handleSearchProject,
  handleSelectAllProjects,
  setOpenModalDelete,
  selectedProjects,
  filters,
  setFilters,
}: ProjectFiltersProps) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const areAllProjectsSelected =
    selectedProjects.length > 0 && selectedProjects.length === projects.length;

  const menuItems = [
    { id: 'creation_date', label: 'common.creation_date' },
    { id: 'last_execution_date', label: 'common.last_execution_date' },
    { id: 'name_project', label: 'common.name_project' },
  ];

  return (
    <>
      <Typography sx={{ width: '33%', flexShrink: 0 }}>
        {t('projects.projectCount', {
          projectCount: projects.length,
          projectPlural: projects.length === 1 ? '' : 's',
        })}
      </Typography>
      <SearchInputCustom
        icon={
          <IconButton onClick={() => handleSearchProject()}>
            <SearchIcon />
          </IconButton>
        }
        handleSearch={() => handleSearchProject()}
        value={filters.projectName}
        onChange={event => {
          setFilters({ ...filters, projectName: event.target.value });
        }}
      />
      <div>
        <label>{t('projects.selectAll')}</label>
        <Checkbox
          checked={areAllProjectsSelected}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleSelectAllProjects(e.target.checked)
          }
          sx={{
            color: '#7024d1',
            '&.Mui-checked': {
              color: '#7024d1',
            },
          }}
        />
        <ButtonCustom
          label={t('common.deleteButton')}
          className={`${selectedProjects.length > 0 ? 'buttonStyleColor' : 'buttonStyleColoOutline'}`}
          variant="contained"
          handleClick={() => setOpenModalDelete(true)}
          tooltip={t('projects.deleteProjectTooltip')}
          disabled={selectedProjects.length === 0}
        />

        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={event => setAnchorEl(event.currentTarget)}
          style={{ float: 'right', color: 'black', textTransform: 'none' }}
        >
          {filters.desc ? (
            <ArrowDownwardIcon style={{ color: 'rgba(0, 0, 0, 0.3)' }} />
          ) : (
            <ArrowUpwardIcon style={{ color: 'rgba(0, 0, 0, 0.3)' }} />
          )}
          {t('common.orderByText', {
            orderCriteria: t(`common.${filters.orderBy}`),
          })}
          <ExpandMoreIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          disableScrollLock={true}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          {menuItems.map(item => (
            <Tooltip
              key={item.id}
              title={t('common.orderByText', { orderCriteria: t(item.label) })}
              placement="right-start"
            >
              <MenuItem
                onClick={() => {
                  const updatedFilters = {
                    ...filters,
                    orderBy: item.id,
                    desc: !filters.desc,
                  };
                  setFilters(updatedFilters);
                }}
              >
                {t(item.label)}
                {filters.orderBy === item.id && (
                  <div>
                    {filters.desc ? (
                      <ArrowDownwardIcon
                        style={{ color: 'rgba(0, 0, 0, 0.3)' }}
                      />
                    ) : (
                      <ArrowUpwardIcon
                        style={{ color: 'rgba(0, 0, 0, 0.3)' }}
                      />
                    )}
                  </div>
                )}
              </MenuItem>
            </Tooltip>
          ))}
        </Menu>
      </div>
    </>
  );
};

export default ProjectFilters;
