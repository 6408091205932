/* eslint-disable no-undef */
import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DeleteIcon from '@mui/icons-material/Delete';

import { useTranslation } from 'react-i18next';
import './style.scss';
import { useEffect, useState } from 'react';
import { IMAGE_TYPES } from '../../constants/mapOptions';
import { getAuthenticatedImage } from '../../services/files';

function UserImageCard(props) {
  const { t } = useTranslation();

  const { image, onCardClick, onDeleteImage } = props;

  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const fetchImageUrl = async () => {
      const authenticatedUrl = await getAuthenticatedImage(image.id)
        .then(response => {
          if (response.status === 200) return response.data.message;
          else return null;
        })
        .catch(() => {
          return null;
        });

      if (authenticatedUrl) {
        setImageUrl(authenticatedUrl);
      }
    };

    fetchImageUrl();
  }, [image?.url_image]);

  const formatDate = (dateString: string) => {
    const fecha = new Date(dateString);

    const day = String(fecha.getDate()).padStart(2, '0');
    const month = String(fecha.getMonth() + 1).padStart(2, '0');
    const year = fecha.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onDeleteImage(image);
  };

  return (
    <ButtonBase onClick={onCardClick}>
      <Card
        sx={{
          width: 300,
          border: image?.selected
            ? '2px solid #7B02D0'
            : '2px solid transparent',
          transition: 'border 0.3s ease',
        }}
        elevation={5}
      >
        {imageUrl ? (
          <CardMedia
            component="img"
            alt="PNG Image"
            height={150}
            image={imageUrl}
          />
        ) : (
          <Skeleton variant="rectangular" width="100%" height={150} />
        )}
        <CardContent sx={{ textAlign: 'left' }}>
          <Grid container>
            <Grid item xs={11}>
              <Typography gutterBottom variant="h5" component="div">
                <strong>{image.name}</strong>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={handleDeleteClick} size="small">
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('images.resolution')}: {image.resolution}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('images.type')}: {IMAGE_TYPES[image.imageType]}
          </Typography>
          <Box
            sx={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}
          >
            <CalendarTodayIcon style={{ marginRight: 8 }} />
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {formatDate(image.imageDate)}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </ButtonBase>
  );
}

export default UserImageCard;
