import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import useStyles from '../../theme/styles';
import Row from './row';
import { useTranslation } from 'react-i18next';

function TableC(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    rows,
    header,
    keys,
    handleChangeCheck,
    downloadActive,
    handleDownloadAoiClick,
    showExtraParameters,
    updateProject,
    setSnackbarProps,
    setOpenSnackbar,
    expanded,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null);
    const { value } = event.currentTarget.dataset;
    if (value) handleDownloadAoiClick(value);
  };

  const getExtraParameters = (
    data,
  ): { name: string; value: string | number }[] => {
    const extraParameters = [];

    if (data.input_alg_parameters?.start_date) {
      const date = `${data.input_alg_parameters?.start_date}`.replace(
        /(\d{4})(\d{2})(\d{2})/,
        '$1-$2-$3',
      );
      extraParameters.push({
        name: t('common.startDate'),
        value: date,
      });
    }
    if (data.input_alg_parameters?.end_date) {
      const date = `${data.input_alg_parameters?.end_date}`.replace(
        /(\d{4})(\d{2})(\d{2})/,
        '$1-$2-$3',
      );
      extraParameters.push({
        name: t('common.endDate'),
        value: date,
      });
    }
    if (data.input_alg_parameters?.cloud_percentage) {
      extraParameters.push({
        name: t('newProject.cloudCoverageField'),
        value: data.input_alg_parameters?.cloud_percentage,
      });
    }

    if (data.input_alg_parameters?.spatial_scale) {
      extraParameters.push({
        name: t('newProject.spatialScaleField'),
        value: data.input_alg_parameters?.spatial_scale,
      });
    }
    if (data.input_alg_parameters?.minimum_segment_size) {
      extraParameters.push({
        name: t('common.minimumSegmentSize'),
        value: data.input_alg_parameters?.minimum_segment_size,
      });
    }
    return extraParameters;
  };

  return (
    <TableContainer component={Paper} className={classes.containerTable}>
      <Table
        sx={{ minWidth: 650 }}
        aria-label="simple table"
        className={classes.headerTableC}
      >
        <TableHead>
          <TableRow>
            {header.map((head: string, index: number) => (
              <TableCell
                align="left"
                width={keys[0] === 'checkbox' ? '5%' : '20%'}
                className={classes.headerTableC}
                key={index}
              >
                {head}
              </TableCell>
            ))}
            {showExtraParameters ? (
              <TableCell
                width={'5%'}
                className={classes.headerTableC}
              ></TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 &&
            rows.map((row, indx: number) => {
              const extraParameters = getExtraParameters(row);
              return (
                <Row
                  handleClick={handleClick}
                  handleClose={handleClose}
                  handleChangeCheck={handleChangeCheck}
                  open={open}
                  anchorEl={anchorEl}
                  downloadActive={downloadActive}
                  keys={keys}
                  row={row}
                  indx={indx}
                  showExtraParameters={showExtraParameters}
                  extraParameters={extraParameters}
                  updateProject={updateProject}
                  setSnackbarProps={setSnackbarProps}
                  setOpenSnackbar={setOpenSnackbar}
                  expanded={expanded}
                />
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableC;
