/* eslint-disable no-undef */
import { Box, Divider, Grid, Modal, Stack, Typography } from "@mui/material";

import { GeoJsonLayer } from "@deck.gl/layers";

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { useEffect, useState } from "react";
import MapMini from "../../components/mapNewProject/map";

import { useTranslation } from "react-i18next";
import "./style.scss";
import ButtonCustom from "../../components/ui/button";
import ImageBuilder from "./imageBuilder";
import ImageViewer from "./imageViewer";
import dayjs from "dayjs";
import TiffViewer from "../../components/tiffViewer";
import * as turf from "@turf/turf";
import CustomSnackbar, { SnackbarProps } from "../../components/snackbar";
import Loading from "../../components/loading";
import { IMAGE_TYPES, INITIAL_VIEW_STATE } from "../../constants/mapOptions";

function UserImages() {
  const { t } = useTranslation();

  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>({
    message: "",
    severity: "info",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [openLoader, setOpenLoader] = useState(false);
  const handleCloseModal = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
  const [viewStateBBOX, setViewStateBBOX] = useState<number[]>([]);
  const [layers, setLayers] = useState([]);

  const [isAddView, setIsAddView] = useState(false);
  const [imageData, setImageData] = useState({
    id: null,
    name: "",
    description: "",
    imageDate: dayjs(),
    imageType: "",
    size: 0,
    resolution: "",
    urlImage: "",
    showUrlImage: "",
    createdAt: null,
    aoi: {},
    originalCrs: "",
    file: null,
  });
  const [images, setImages] = useState([]);

  const handleAddImageClick = () => {
    setIsAddView(!isAddView);
  };

  const selectImage = (image) => {
    const newImages = images.map((img) => {
      if (img.id === image.id) {
        return {
          ...img,
          selected: !img.selected,
        };
      }
      return {
        ...img,
        selected: false,
      };
    });
    setImages(newImages);
  };

  useEffect(() => {
    const image = images.filter((img) => img.selected)
    if (image.length > 0) {
      const bbox = turf.bbox(image[0].aoi);

      setLayers([
        new GeoJsonLayer({
          id: "geoTiff-layer",
          data: image[0].aoi,
          filled: true,
          getFillColor: [112, 39, 209, 180],
          stroked: false,
          getLineWidth: 0,
        }),
      ]);
      setViewStateBBOX(bbox);
    } else {
      setLayers([]);
    }
  }, [images])

  const getSelectedImage = () => {
    const selectedImages = images.filter((img) => img.selected);
    return selectedImages?.length ? selectedImages[0] : null;
  };

  const formatDate = (dateString: string) => {
    const fecha = new Date(dateString);

    const day = String(fecha.getDate()).padStart(2, "0");
    const month = String(fecha.getMonth() + 1).padStart(2, "0");
    const year = fecha.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const renderSelectedImageInfo = () => {
    const selectedImage = getSelectedImage();
    if (selectedImage) {
      return (
        <Stack direction="column" sx={{ paddingTop: 2 }}>
          <Typography variant="h6">
            {selectedImage.name} | {formatDate(selectedImage.imageDate)}
          </Typography>
          <Typography> Description: {selectedImage.description}</Typography>
        </Stack>
      );
    }
    return null;
  };
  const renderSelectedImageExtraInfo = () => {
    const selectedImage = getSelectedImage();
    if (selectedImage) {
      return (
        <Stack sx={{ paddingTop: 2 }} spacing={1}>
          <Typography>
            {t("images.size")}: {`${(selectedImage.size / 1024 / 1024).toFixed(2)} MB`}
          </Typography>
          <Typography>
            {" "}
            {t("images.resolution")}: {selectedImage.resolution}
          </Typography>
          <Typography>
            {" "}
            {t("images.type")}: {IMAGE_TYPES[selectedImage.imageType]}
          </Typography>
          <Typography>
            {" "}
            {t("images.crs")}: {selectedImage.originalCrs ? selectedImage.originalCrs : "--"}
          </Typography>
        </Stack>
      );
    }
    return null;
  };

  return (
    <div>
      <Modal
        open={openLoader}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="containerLoader">
          <Loading />
        </div>
      </Modal>
      <Box className="body_container_newA">
        <Box className="body_container_newA_images" sx={{ height: "85vh" }}>
          <div className="body_container_newA_div_opt">
            <Typography sx={{ fontWeight: "bold" }} variant="h4">
              {t("images.title")}
            </Typography>
          </div>
          {isAddView ? (
            <ImageBuilder
              imageData={imageData}
              setImageData={setImageData}
              setIsAddView={setIsAddView}
              setLayers={setLayers}
              setOpenSnackbar={setOpenSnackbar}
              setSnackbarProps={setSnackbarProps}
              setOpenLoader={setOpenLoader}
            />
          ) : (
            <ImageViewer
              images={images}
              setImages={setImages}
              setLayers={setLayers}
              onCardClick={selectImage}
              setOpenSnackbar={setOpenSnackbar}
              setSnackbarProps={setSnackbarProps}
              setOpenLoader={setOpenLoader}
            />
          )}
        </Box>
        <Divider orientation="vertical" flexItem style={{ height: "85vh", left: "50%" }} />
        <Box className="body_container_newA_preview">
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            {t("common.preview")}
          </Typography>
          <div>{renderSelectedImageInfo()}</div>
          <Box className="centeredContainer" sx={{ paddingTop: getSelectedImage() ? 3 : 12 }}>
            {isAddView ? (
              <Box className="centeredContainer" sx={{ background: "#FFF" }}>
                {imageData.file ? (
                  <Grid container sx={{ height: "100%", width: "100%" }}>
                    <Grid item xs={12} sx={{ height: "100%", width: "100%" }}>
                      <TiffViewer file={imageData.file} />
                    </Grid>
                  </Grid>
                ) : (
                  <Typography>{t("images.uploadImagePlaceholder")}</Typography>
                )}
              </Box>
            ) : (
              <MapMini
                layersArray={layers}
                btnDrawActive={false}
                viewState={viewState}
                setViewState={setViewState}
                viewStateBBOX={viewStateBBOX}
                setViewStateBBOX={setViewStateBBOX}
              />
            )}
          </Box>

          <Grid container>
            <Grid item xs={6}>
              {renderSelectedImageExtraInfo()}
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: "flex", justifyContent: "right" }}>
                <ButtonCustom
                  label={isAddView ? t("common.backButton") : t("images.addImage")}
                  className="buttonBorderRad"
                  variant="contained"
                  handleClick={handleAddImageClick}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CustomSnackbar snackbarProps={snackbarProps} openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} />
    </div>
  );
}

export default UserImages;
