import { apiClient } from "./axiosConfig";
import { apiPaths } from "./config";

export const getProjectByUser = async (
  page: number,
  projectName: string | null = null,
  orderBy: string | null = null,
  isOrderByDesc: boolean | null = null,
  datasetStatus: string | null = null,
) => {
  const params = {
    name_project: projectName,
    order_by: orderBy,
    desc: isOrderByDesc,
    dataset_status: datasetStatus,
  };

  return await apiClient.get(`${apiPaths.project}/list_projects/${page}`, { params: params });
};

export const getNumberPages = async (projectName: string | null = null, datasetStatus: string | null = null) => {
  const params = {
    name_project: projectName,
    dataset_status: datasetStatus,
  };

  return await apiClient.get(`${apiPaths.project}/number_pages`, { params: params });
};

export const getProjectById = async (data: any) => {
  return await apiClient.get(`${apiPaths.projectAlgorithm}/filter_project_algorithm/${data}`);
};

export const createProject = async (projectName: string, projectDescription: string) => {
  const formData = new FormData();

  formData.append("name_project", projectName);
  formData.append("description", projectDescription);

  return await apiClient.post(`${apiPaths.project}/create_project`, formData);
};

export const deleteProject = async (projectId: number) => {
  return await apiClient.delete(`${apiPaths.project}/delete_project/${projectId}`);
};

export const existsProject = async (projectName: string) => {
  const params = projectName === null ? {} : { name_project: projectName };

  return await apiClient.get(`${apiPaths.project}/exist_project`, { params: params });
};

export const editProject = async (idProject: number, projectFields: any) => {
  const formData = new FormData();

  Object.entries(projectFields).forEach((entry: any) => {
    if (entry[1]) formData.append(entry[0], entry[1]);
  });

  return await apiClient.patch(`${apiPaths.project}/edit_project/${idProject}`, formData);
};

export const editComment = async (idProject: number, projectFields: any) => {
  const formData = new FormData();

  Object.entries(projectFields).forEach((entry: any) => {
    if (entry[1]) formData.append(entry[0], entry[1]);
  });

  return await apiClient.patch(`${apiPaths.project}/comments/${idProject}`, formData);
};

export const addComment = async (idProject: number, comment: string) => {
  const formData = new FormData();
  formData.append("comment", comment);

  return await apiClient.post(`${apiPaths.project}/comments/${idProject}`, formData);
};
