import { Box, Card, CardContent, Checkbox, Grid, IconButton, Typography } from "@mui/material";
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import useStyles from "../../../../theme/styles";


function SharingCard(props: any) {

    const {
        dataset,
        isSelected,
        handleCheckboxChange,
        handleCopyDatasetUrl
    } = props

    const classes = useStyles();
    
    return (
        <Card sx={{ minWidth: 275 }} key={`${dataset.id_project}-${dataset.id_dataset}`}>
            <CardContent className={classes.card_content}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={2}>
                        <Checkbox
                            checked={isSelected(dataset.id_dataset)}
                            onChange={() => handleCheckboxChange(dataset.id_dataset)}
                            inputProps={{ "aria-label": `select card ${dataset.id_dataset}` }}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Box>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {dataset.dataset_name}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton onClick={() => handleCopyDatasetUrl(dataset)}>
                            <MapOutlinedIcon sx={{ color: "#000" }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default SharingCard;