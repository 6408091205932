import lansat_img from "../../img/Landsat.jpg";
import sentinel_img from "../../img/Sentinel.jpg";
import panet_img from "../../img/Planetscope.jpg";
import lidar_img from "../../img/LIDAR.jpg";
import pleiades_img from "../../img/Pleiades.jpg";
import spot_img from "../../img/Spot.jpg";
import world_img from "../../img/Worldview.jpg";

export const dataImageCatalogFavourite = {
  0: {
    title: "Lidar",
    content:
      "Very high resolution point clouds, containing measurements of several points per square metre and allowing3D measurements.",
    date: "04/18/2022",
    times: "20 times",
    imgUrl: lidar_img,
  },
  1: {
    title: "Sentinel (Copernicus)",
    content:
      "A constellation of two identical satellites in the same orbit, Copernicus Sentinel-2 images land and coastal areas at high spatial resolution in the optical domain.",
    date: "04/15/2022",
    times: "18 times",
    imgUrl: sentinel_img,
  },
  2: {
    title: "Landsat (USGS)",
    content:
      "Landsat 9 will continue the Landsat program’s critical role of repeat global observations for monitoring, understanding, and managing Earth’s natural resources.",
    date: "04/03/2022",
    times: "23 times",
    imgUrl: lansat_img,
  },
};

export const dataImageCatalog = {
  0: {
    title: "Pleiades (Airbus)",
    content:
      "Providing 30 cm resolution combined with the most accurate geolocation, Pléiades Neo brings a significant level of information required for precise large-scale mapping in a military or civilian.",
    date: "03/03/2022",
    times: "15 times",
    imgUrl: pleiades_img,
  },
  1: {
    title: "Sentinel (Copernicus)",
    content:
      "A constellation of two identical satellites in the same orbit, Copernicus Sentinel-2 images land and coastal areas at high spatial resolution in the optical domain.",
    date: "04/15/2022",
    times: "18 times",
    imgUrl: sentinel_img,
  },
  2: {
    title: "Spot (Airbus)",
    content:
      "SPOT 6 and SPOT 7 satellites imagery offer an affordable source of information for analysing, monitoring, forecasting and managing resources and human activity on our planet.",
    date: "04/01/2022",
    times: "20 times",
    imgUrl: spot_img,
  },
  3: {
    title: "Landsat (USGS)",
    content:
      "Landsat 9 will continue the Landsat program’s critical role of repeat global observations for monitoring, understanding, and managing Earth’s natural resources.",
    date: "04/03/2022",
    times: "23 times",
    imgUrl: lansat_img,
  },
  4: {
    title: "Worldview (Maxar)",
    content:
      "Provides high resolution optical imagery. The WorldView constellations consists of four active satellites: WorldView-1, GeoEye-1, WorldView-2 and WorldView-3.",
    date: "03/22/2022",
    times: "14 times",
    imgUrl: world_img,
  },
  5: {
    title: "PlanetScope (Planet)",
    content: "Providing 3m resolution brings a significant level of information in 4 band (RGB, NIR).",
    date: "04/05/2022",
    times: "20 times",
    imgUrl: panet_img,
  },
  6: {
    title: "Lidar",
    content:
      "Very high resolution point clouds, containing measurements of several points per square metre and allowing3D measurements.",
    date: "04/18/2022",
    times: "20 times",
    imgUrl: lidar_img,
  },
};
