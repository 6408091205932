import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Box,
} from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { Tooltip, IconButton } from '@mui/material';
import ButtonCustom from '../../../components/ui/button';
import useStyles from '../../../theme/styles';
import { useTranslation } from 'react-i18next';
import { AlgorithmMessage } from '../../../models/algorithm';
import {
  Asset,
  AttributeListMaestros,
  InputImages,
  Objective,
} from '../../../models/project';
import { initialOption } from '../../../constants';

interface Step1Props {
  className: string;
  objective: Objective[];
  setObjective: (objective: Objective[]) => void;
  maxPossibleAssets: number;
  activeStep: number;
  stepIndex: number;
  algorithmList: AlgorithmMessage[];
  attributeListMaestros: AttributeListMaestros[];
  assetList: Asset[];
  inputImages: InputImages[];
  setInputImages;
  isNextStepBlocked: boolean;
  handleStep: (step: number) => (event: React.MouseEvent<HTMLElement>) => void;
}

function Step1(props: Step1Props) {
  const {
    className,
    objective,
    setObjective,
    maxPossibleAssets,
    activeStep,
    stepIndex,
    algorithmList,
    attributeListMaestros,
    assetList,
    inputImages,
    setInputImages,
    isNextStepBlocked,
    handleStep,
  } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  const handleGenericChangeSelect = (e: SelectChangeEvent, index: number) => {
    const { name, value } = e.target;

    const defaultImageType: any = {};

    const attr = attributeListMaestros.find(
      (att: AttributeListMaestros) =>
        att.id_algorithm_attribute === Number(value),
    );
    if (name === 'attribute' && attr && attr.base_image) {
      const baseImageKeys = Object.entries(attr.available_images).reduce(
        (acc: Record<string, string[]>, [key, values]) => {
          if (key !== "default")
            acc[key] = values.map(v => {
              return { subimageName: v.subimageName, id: v.id }
            });
          return acc;
        },
        {}
      )
      defaultImageType['default'] = attr.available_images['default'][0];

      const newInputImages = inputImages.map((o: InputImages, idx: number) => {
        return index === idx ? baseImageKeys : o;
      });
      setInputImages(newInputImages);
    }

    const newObjective = objective.map((o: Objective, idx: number) => {
      if (index === idx) {
        return {
          ...o,
          attribute: name === 'asset' ? null : o.attribute,
          selectImageType: defaultImageType.default
            ? Object.keys(defaultImageType.default)[0]
            : '',
          selectSubImageType: defaultImageType.default
            ? {subimageName: String(Object.values(defaultImageType.default)[0]), id: undefined}
            : {},
          [name]: value,
        };
      } else {
        return o;
      }
    });
    setObjective(newObjective);
  };

  const handleClickCloneUseCase = () => {
    if (objective.length <= maxPossibleAssets) {
      setObjective([...objective, initialOption]);
      setInputImages([...inputImages, {}]);
    }
  };

  const handleClickRemoveUseCase = (
    activeStep: number,
    stepIndex: number,
    index: number,
  ) => {
    if (activeStep === stepIndex) {
      setObjective(
        objective.filter((_: Objective, idx: number) => idx !== index),
      );
      setInputImages(
        inputImages.filter((_: InputImages, idx: number) => idx !== index),
      );
    }
  };

  const getAvailabeAttributes = (assetIndex: number) => {
    if (algorithmList.length > 0 && objective[assetIndex]?.asset) {
      const listadoAlgorithmList = algorithmList.filter(
        a => a.id_algorithm_asset === objective[assetIndex]?.asset,
      );
      const list = [];
      listadoAlgorithmList.map((item: AlgorithmMessage) => {
        const e = attributeListMaestros.find(
          (e: AttributeListMaestros) =>
            e.id_algorithm_attribute === item.id_algorithm_attribute,
        );
        if (e !== undefined) {
          list.push(e);
        }
      });
      const listSorted = list.sort((a, b) =>
        a.name_attribute.localeCompare(b.name_attribute),
      );
      return listSorted;
    }
    return [];
  };

  return (
    <div className={className}>
      {objective.map((o: Objective, objectiveIndex: number) => {
        return (
          <div key={`objective-${objectiveIndex}`}>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 200 }}
              className="select_white"
              disabled={activeStep !== stepIndex}
            >
              <InputLabel className="label_select" style={{ fontSize: '12px' }}>
                {/**TODO meter traducciones para esto?*/}
                {t('common.asset')}
              </InputLabel>
              <Select
                onChange={(e: SelectChangeEvent) =>
                  handleGenericChangeSelect(e, objectiveIndex)
                }
                value={String(o.asset || '')}
                name="asset"
                className={classes.selectColor}
              >
                {assetList &&
                  assetList.map((asset: Asset) => (
                    <MenuItem
                      value={asset.id_algorithm_asset || ''}
                      key={asset.id_algorithm_asset}
                      disabled={!asset.available}
                    >
                      {asset.name_asset}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 200 }}
              className="select_white"
              disabled={activeStep !== stepIndex}
            >
              <InputLabel className="label_select" style={{ fontSize: '12px' }}>
                {/**TODO meter traducciones para esto?*/}
                {t('common.attribute')}
              </InputLabel>
              <Select
                onChange={(e: SelectChangeEvent) =>
                  handleGenericChangeSelect(e, objectiveIndex)
                }
                value={String(o.attribute) || ''}
                name="attribute"
                className={classes.selectColor}
              >
                {attributeListMaestros &&
                  getAvailabeAttributes(objectiveIndex).map(attribute => {
                    return (
                      <MenuItem
                        value={attribute.id_algorithm_attribute || ''}
                        key={attribute.id_algorithm_attribute}
                        disabled={!attribute.available}
                      >
                        {attribute.name_attribute}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            {objectiveIndex > 0 ? (
              <Tooltip
                title={t('newProject.removeDatasetButton')}
                placement="top-end"
              >
                <IconButton
                  disabled={activeStep !== stepIndex}
                  onClick={() =>
                    handleClickRemoveUseCase(
                      activeStep,
                      stepIndex,
                      objectiveIndex,
                    )
                  }
                  style={{ marginTop: '14px' }}
                >
                  <RemoveCircleOutlineIcon
                    className={`colorRed ${activeStep === stepIndex ? classes.cursorBtn : ''}`}
                  />
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
        );
      })}
      {objective.length < maxPossibleAssets && (
        <ButtonCustom
          className={`step1_footer ${activeStep === stepIndex ? classes.cursorBtn : ''}`}
          handleClick={handleClickCloneUseCase}
          startIcon={<AddCircleOutlineIcon />}
          disabled={activeStep !== stepIndex}
          tooltip={t('common.addTooltip')}
          label={t('common.addButton')}
        />
      )}
      <Box sx={{ mb: 2 }}>
        <div className={`${stepIndex !== activeStep ? classes.hideBtn : ''}`}>
          <ButtonCustom
            label={t('common.continueButton')}
            className={
              isNextStepBlocked ? 'buttonStyleColorDisable' : 'buttonStyleColor'
            }
            variant="contained"
            handleClick={handleStep(1)}
            tooltip={t('common.continueTooltip', {
              extraText: t('common.continueAOI'),
            })}
            disabled={isNextStepBlocked}
          />
        </div>
      </Box>
    </div>
  );
}

export default Step1;
