import { Alert, Snackbar, SnackbarCloseReason, AlertColor } from "@mui/material";

export interface SnackbarProps {
  message: string;
  severity: AlertColor;
}

interface CustopmSnackbarProps {
  snackbarProps: SnackbarProps;
  openSnackbar: boolean;
  setOpenSnackbar: (value: boolean) => void;
}

function CustomSnackbar(props: CustopmSnackbarProps) {
  const { snackbarProps, openSnackbar, setOpenSnackbar } = props;

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Snackbar
      open={openSnackbar}
      onClose={handleCloseSnackbar}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert onClose={handleCloseSnackbar} severity={snackbarProps.severity} variant="filled" sx={{ width: "100%" }}>
        {snackbarProps.message}
      </Alert>
    </Snackbar>
  );
}

export default CustomSnackbar;
