import { MVTLayer } from '@deck.gl/geo-layers';
import { scaleThreshold } from 'd3-scale';
import {
  COLOR_SCALE_ASPECT,
  COLOR_SCALE_EARTHWORK_MONITORING,
  COLOR_SCALE_HUMIDITY,
  COLOR_SCALE_NDVI,
  COLOR_SCALE_NIGHT_TIME_LIGHTS,
  COLOR_SCALE_SLOPE,
  COLOR_SCALE_VEGETATION_HEALTH,
  COLOR_SCALE_WATER_BODIES,
} from '../../../constants';
import { getMVTLayerProps } from '../../../models/dashboard';
import { MARTIN } from '../../../config/env';

export const getMVTName = (
  name_attribute: string,
  id_dataset: string,
  id_project: string,
) => {
  const layerMap: Record<string, string> = {
    Radiation: `mvtRadiation_${id_dataset}_${id_project}`,
    Aspect: `mvtAspect_${id_dataset}_${id_project}`,
    'Slope (Pitch)': `mvtSlope_${id_dataset}_${id_project}`,
    NDVI: 'mvtNDVI',
    Altitude: `mvtAltitude_${id_dataset}_${id_project}`,
    Watersheds: `mvtWatersheds${id_dataset}_${id_project}`,
    'Solar Panels': `mvtSolarPanels${id_dataset}_${id_project}`,
    'Building Footprints': `mvtBuildingFootprints${id_dataset}_${id_project}`,
    Height: `mvtHeight${id_dataset}_${id_project}`,
    'Vegetation health': `mvtVegetationHealth${id_dataset}_${id_project}`,
    Powerblocks: `mvtPowerblocks${id_dataset}_${id_project}`,
    'Roads (Solar Plants)': `mvtRoads${id_dataset}_${id_project}`,
    'Solar Panels Plants': `mvtSolarPanelsPlants${id_dataset}_${id_project}`,
    'Solar Trackers Plants': `mvtSolarTrackersPlants${id_dataset}_${id_project}`,
    'Wind Turbines': `mvtWindTurbines${id_dataset}_${id_project}`,
    Humidity: `mvtHumidity${id_dataset}_${id_project}`,
    'Water bodies': `mvtWaterBodies${id_dataset}_${id_project}`,
    Segmentation: `mvtSegmentation${id_dataset}_${id_project}`,
    'Rooftop Type': `mvtRooftopType${id_dataset}_${id_project}`,
    'Vegetation height': `mvtVegetationHeight_${id_dataset}_${id_project}`,
    Monitoring: `mvtMonitoring_${id_dataset}_${id_project}`,
    'Burned areas': `mvtBurnedareas${id_dataset}_${id_project}`,
    'Night time lights': `mvtNightTimeLights_${id_dataset}_${id_project}`,
    'Contour Lines': `mvtContourLines_${id_dataset}_${id_project}`,
    Vehicles: `mvtVehicles_${id_dataset}_${id_project}`,
    Roads: `mvtRoads_${id_dataset}_${id_project}`,
    'Earthworks Monitoring': `mvtEarthworksMonitoring_${id_dataset}_${id_project}`,
    'High Tension Towers': `mvtHighTensionTowers_${id_dataset}_${id_project}`,
    'Medium Tension Towers': `mvtMediumTensionTowers_${id_dataset}_${id_project}`,
    Trenches: `mvtTrenches_${id_dataset}_${id_project}`,
  };

  return layerMap[name_attribute] || '';
};

export const getMVTLayer = async ({
  name_attribute,
  id_project,
  id_dataset,
  transparency,
  optionalParameters,
  subimage,
  dataResults,
}: getMVTLayerProps) => {
  if (name_attribute === 'Radiation') {
    const domainGroups = optionalParameters?.kpisGroups
      ? optionalParameters.kpisGroups
      : dataResults[id_dataset].groups;

    const COLOR_SCALE_RADIATION = scaleThreshold<number, number[]>()
      .domain(domainGroups)
      .range([
        [59, 27, 0],
        [93, 42, 0],
        [171, 86, 3],
        [198, 105, 5],
        [237, 165, 33],
        [250, 198, 53],
        [255, 230, 70],
      ]);
    return new MVTLayer({
      id: `mvtRadiation_${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_radiation/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      //onViewportLoad,
      //radiusPixels: 0.1,
      getPointRadius: subimage !== 'DTM' ? 0.7 : 2,
      pickable: true,
      //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
      getFillColor: (f): any => {
        if (f.properties.radiation !== null && f.properties.radiation > 0) {
          return COLOR_SCALE_RADIATION(f.properties.radiation);
        }
      },
      getLineWidth: 0.5,
      getLineColor: f => {
        if (f.properties.radiation !== null && f.properties.radiation > 0) {
          return [0, 0, 0, 1];
        }
      },
    });
  } else if (name_attribute === 'Aspect') {
    return new MVTLayer({
      id: `mvtAspect_${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_aspect/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      //onViewportLoad,
      //radiusPixels: 0.1,
      getPointRadius: subimage !== 'DTM' ? 0.7 : 2,
      pickable: true,
      //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
      getFillColor: (f): any => {
        if (f.properties.aspect !== null && f.properties.aspect > 0) {
          return COLOR_SCALE_ASPECT(f.properties.aspect);
        }
      },
      getLineWidth: 0.5,
      getLineColor: f => {
        if (f.properties.aspect !== null && f.properties.aspect > 0) {
          return [0, 0, 0, 1];
        }
      },
    });
  } else if (name_attribute === 'Slope (Pitch)') {
    return new MVTLayer({
      id: `mvtSlope_${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_slope/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      //onViewportLoad,
      //radiusPixels: 0.1,
      getPointRadius: subimage !== 'DTM' ? 0.7 : 2,
      pickable: true,
      //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
      getFillColor: (f): any => {
        if (f.properties.slope !== null && f.properties.slope > 0) {
          return COLOR_SCALE_SLOPE(f.properties.slope);
        }
      },
      getLineWidth: 0.5,
      getLineColor: f => {
        if (f.properties.slope !== null && f.properties.slope > 0) {
          return [0, 0, 0, 1];
        }
      },
    });
  } else if (name_attribute === 'NDVI') {
    return new MVTLayer({
      id: 'mvtNDVI',
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_ndvi/{z}/{x}/{y}.pbf`,
      //onViewportLoad,
      //radiusPixels: 0.1,
      getPointRadius: subimage !== 'DTM' ? 0.7 : 2,
      pickable: true,
      //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
      getFillColor: (f): any => {
        if (
          f.properties.ndvi !== null &&
          f.properties.ndvi > 0 &&
          f.properties.ndvi <= 1
        ) {
          return COLOR_SCALE_NDVI(f.properties.ndvi);
        }
      },
      getLineWidth: 0.5,
      getLineColor: f => {
        if (
          f.properties.ndvi !== null &&
          f.properties.ndvi > 0 &&
          f.properties.ndvi <= 1
        ) {
          return [0, 0, 0, 1];
        }
      },
    });
  } else if (name_attribute === 'Altitude') {
    const domainGroups = optionalParameters?.kpisGroups
      ? optionalParameters.kpisGroups
      : dataResults[id_dataset].groups;

    const COLOR_SCALE_ALTITUDE = scaleThreshold<number, number[]>()
      .domain(domainGroups)
      .range([
        [0, 102, 51],
        [0, 153, 51],
        [0, 204, 102],
        [153, 255, 102],
        [255, 255, 102],
        [255, 204, 102],
        [255, 153, 51],
        [204, 102, 0],
        [153, 102, 51],
        [102, 51, 0],
      ]);
    return new MVTLayer({
      id: `mvtAltitude_${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_altitude/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      //onViewportLoad,
      //radiusPixels: 0.1,
      getPointRadius: subimage !== 'DTM' ? 0.7 : 2,
      pickable: true,
      //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
      getFillColor: (f): any => {
        if (f.properties.altitude !== null && f.properties.altitude > 0) {
          return COLOR_SCALE_ALTITUDE(f.properties.altitude);
        }
      },
      getLineWidth: 0.5,
      getLineColor: f => {
        if (f.properties.altitude !== null && f.properties.altitude > 0) {
          return [0, 0, 0, 1];
        }
      },
    });
  } else if (name_attribute === 'Vegetation height') {
    const domainGroups = optionalParameters?.kpisGroups
      ? optionalParameters.kpisGroups
      : dataResults[id_dataset].groups;

    const COLOR_SCALE_ALTITUDE = scaleThreshold<number, number[]>()
      .domain(domainGroups)
      .range([
        [0, 102, 51],
        [0, 153, 51],
        [0, 204, 102],
        [153, 255, 102],
        [255, 255, 102],
        [255, 204, 102],
        [255, 153, 51],
        [204, 102, 0],
        [153, 102, 51],
        [102, 51, 0],
      ]);
    return new MVTLayer({
      id: `mvtVegetationHeight_${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_vegetation_height/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      //onViewportLoad,
      //radiusPixels: 0.1,
      getPointRadius: subimage !== 'DTM' ? 0.7 : 2,
      pickable: true,
      //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
      getFillColor: (f): any => {
        if (
          f.properties.vegetation_height !== null &&
          f.properties.vegetation_height > 0
        ) {
          return COLOR_SCALE_ALTITUDE(f.properties.vegetation_height);
        }
      },
      getLineWidth: 0.5,
      getLineColor: f => {
        if (
          f.properties.vegetation_height !== null &&
          f.properties.vegetation_height > 0
        ) {
          return [0, 0, 0, 1];
        }
      },
    });
  } else if (name_attribute === 'Watersheds') {
    return new MVTLayer({
      id: `mvtWatersheds${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_watersheds/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      pickable: true,
      getFillColor: [112, 39, 209, 0],
      getLineWidth: 0.5,
      getLineColor: [112, 39, 209],
      //lineWidthUnits: "pixels",
    });
  } else if (name_attribute === 'Solar Panels') {
    return new MVTLayer({
      id: `mvtSolarPanels${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_solar_panels/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      pickable: true,
      getFillColor: [112, 39, 209],
      getLineWidth: 0.1,
      getLineColor: [0, 0, 0],
    });
  } else if (name_attribute === 'Building Footprints') {
    return new MVTLayer({
      id: `mvtBuildingFootprints${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_building_footprints/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      pickable: true,
      getFillColor: [112, 39, 209, 0],
      getLineWidth: 2,
      getLineColor: [112, 39, 209],
      lineWidthUnits: 'pixels',
    });
  } else if (name_attribute === 'Rooftop Type') {
    return new MVTLayer({
      id: `mvtRooftopType${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_building_rooftop_type/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      pickable: true,
      getFillColor: [112, 39, 209, 0],
      getLineWidth: 2,
      getLineColor: [112, 39, 209],
      lineWidthUnits: 'pixels',
    });
  } else if (name_attribute === 'Height') {
    const domainGroups = optionalParameters?.kpisGroups
      ? optionalParameters.kpisGroups
      : dataResults[id_dataset].groups;

    const COLOR_SCALE_ALTITUDE = scaleThreshold<number, number[]>()
      .domain(domainGroups)
      .range([
        [0, 102, 51],
        [0, 153, 51],
        [0, 204, 102],
        [153, 255, 102],
        [255, 255, 102],
        [255, 204, 102],
        [255, 153, 51],
        [204, 102, 0],
        [153, 102, 51],
        [102, 51, 0],
      ]);
    return new MVTLayer({
      id: `mvtHeight${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_height/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      //onViewportLoad,
      //radiusPixels: 0.1,
      getPointRadius: subimage !== 'DTM' ? 0.7 : 2,
      pickable: true,
      //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
      getFillColor: (f): any => {
        if (f.properties.height !== null && f.properties.height > 0) {
          return COLOR_SCALE_ALTITUDE(f.properties.height);
        }
      },
      getLineWidth: 0.5,
      getLineColor: f => {
        if (f.properties.height !== null && f.properties.height > 0) {
          return [0, 0, 0, 1];
        }
      },
    });
  } else if (name_attribute === 'Vegetation health') {
    return new MVTLayer({
      id: `mvtVegetationHealth${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_vegetation_health/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      //onViewportLoad,
      //radiusPixels: 0.1,
      getPointRadius: subimage !== 'DTM' ? 0.7 : 2,
      pickable: true,
      //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
      getFillColor: (f): any => {
        if (f.properties.ndvi !== null) {
          return COLOR_SCALE_VEGETATION_HEALTH(f.properties.ndvi);
        }
      },
      getLineWidth: 0.5,
      getLineColor: f => {
        if (f.properties.ndvi !== null) {
          return [0, 0, 0, 1];
        }
      },
    });
  } else if (name_attribute === 'Powerblocks') {
    return new MVTLayer({
      id: `mvtPowerblocks${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_powerblocks/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      pickable: true,
      getFillColor: [112, 39, 209, 0],
      getLineWidth: 2,
      getLineColor: [112, 39, 209],
      lineWidthUnits: 'pixels',
    });
  } else if (name_attribute === 'Roads (Solar Plants)') {
    return new MVTLayer({
      id: `mvtRoads${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_solar_panels_plants_roads/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      pickable: true,
      getFillColor: [112, 39, 209, 0],
      getLineWidth: 2,
      getLineColor: [112, 39, 209],
      lineWidthUnits: 'pixels',
    });
  } else if (name_attribute === 'Solar Panels Plants') {
    return new MVTLayer({
      id: `mvtSolarPanelsPlants${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_solar_panels_plants/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      pickable: true,
      getFillColor: [112, 39, 209, 0],
      getLineWidth: 2,
      getLineColor: [112, 39, 209],
      lineWidthUnits: 'pixels',
    });
  } else if (name_attribute === 'Solar Trackers Plants') {
    return new MVTLayer({
      id: `mvtSolarTrackersPlants${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_solar_panels_plants_trackers/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      pickable: true,
      getFillColor: [112, 39, 209, 0],
      getLineWidth: 2,
      getLineColor: [112, 39, 209],
      lineWidthUnits: 'pixels',
    });
  } else if (name_attribute === 'Wind Turbines') {
    return new MVTLayer({
      id: `mvtWindTurbines${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_wind_turbines/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      pickable: true,
      getLineWidth: 2,
      getFillColor: [0, 0, 0, 0],
      getLineColor: [112, 39, 209],
    });
  } else if (name_attribute === 'Humidity') {
    return new MVTLayer({
      id: `mvtHumidity${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_humidity/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      //onViewportLoad,
      //radiusPixels: 0.1,
      getPointRadius: subimage !== 'DTM' ? 0.7 : 2,
      pickable: true,
      //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
      getFillColor: (f): any => {
        if (f.properties.ndwi !== null) {
          return COLOR_SCALE_HUMIDITY(f.properties.ndwi);
        }
      },
      getLineWidth: 0.5,
      getLineColor: f => {
        if (f.properties.ndwi !== null) {
          return [0, 0, 0, 1];
        }
      },
    });
  } else if (name_attribute === 'Water bodies') {
    return new MVTLayer({
      id: `mvtWaterBodies${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_water_bodies/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      //onViewportLoad,
      //radiusPixels: 0.1,
      getPointRadius: subimage !== 'DTM' ? 0.7 : 2,
      pickable: true,
      //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
      getFillColor: (f): any => {
        if (f.properties.ndwi !== null) {
          return COLOR_SCALE_WATER_BODIES(f.properties.ndwi);
        }
      },
      getLineWidth: 0.5,
      getLineColor: f => {
        if (f.properties.ndwi !== null) {
          return [0, 0, 0, 1];
        }
      },
    });
  } else if (name_attribute === 'Segmentation') {
    return new MVTLayer({
      id: `mvtSegmentation${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_segmentation/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      pickable: true,
      getFillColor: [112, 39, 209, 0],
      getLineWidth: 2,
      getLineColor: [112, 39, 209],
      lineWidthUnits: 'pixels',
    });
  } else if (name_attribute === 'Burned areas') {
    return new MVTLayer({
      id: `mvtBurnedareas${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_burned_areas/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      pickable: true,
      getFillColor: [112, 39, 209],
      getLineWidth: 2,
      getLineColor: [112, 39, 209],
      lineWidthMinPixels: 2,
    });
  } else if (name_attribute === 'Monitoring') {
    return new MVTLayer({
      id: `mvtMonitoring_${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_monitoring/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      getPointRadius: subimage !== 'DTM' ? 0.7 : 2,
      pickable: true,
      getLineWidth: 2,
      getFillColor: [0, 0, 0, 0],
      getLineColor: [112, 39, 209],
    });
  } else if (name_attribute === 'Night time lights') {
    return new MVTLayer({
      id: `mvtNightTimeLights_${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_night_time_lights/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      getPointRadius: subimage !== 'DTM' ? 0.7 : 2,
      pickable: true,
      getFillColor: (f): any => {
        if (f.properties.luminosity !== null) {
          return COLOR_SCALE_NIGHT_TIME_LIGHTS(f.properties.luminosity);
        }
      },
    });
  } else if (name_attribute === 'Contour Lines') {
    return new MVTLayer({
      id: `mvtContourLines_${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_contour_lines/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      getPointRadius: subimage !== 'DTM' ? 0.7 : 2,
      pickable: true,
      getLineWidth: ({ properties }) => {
        const zoom = properties.z;
        return subimage !== 'DTM' ? 0.3 : 19 - 0.9 * zoom;
      },
      getFillColor: [0, 0, 0, 0],
      getLineColor: [112, 39, 209],
    });
  } else if (name_attribute === 'Vehicles') {
    return new MVTLayer({
      id: `mvtVehicles_${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_vehicles/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      getPointRadius: subimage !== 'DTM' ? 0.7 : 2,
      pickable: true,
      getLineWidth: 0.3,
      getFillColor: [0, 0, 0, 0],
      getLineColor: [112, 39, 209],
    });
  } else if (name_attribute === 'Roads') {
    return new MVTLayer({
      id: `mvtRoads_${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_roads/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      getPointRadius: subimage !== 'DTM' ? 0.7 : 2,
      pickable: true,
      getLineWidth: 0.3,
      getFillColor: [112, 39, 209],
      getLineColor: [112, 39, 209],
    });
  } else if (name_attribute === 'Earthworks Monitoring') {
    return new MVTLayer({
      id: `mvtEarthworksMonitoring_${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_earthworks_monitoring/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      getPointRadius: subimage !== 'DTM' ? 0.7 : 2,
      pickable: true,
      getLineColor: [0, 0, 0, 0],
      getFillColor: (f): any => {
        if (f.properties.category_id !== null) {
          return COLOR_SCALE_EARTHWORK_MONITORING(f.properties.category_id);
        }
      },
    });
  } else if (name_attribute === 'High Tension Towers') {
    return new MVTLayer({
      id: `mvtHighTensionTowers_${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_high_tension_towers/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      pickable: true,
      getLineWidth: 2,
      getFillColor: [0, 0, 0, 0],
      getLineColor: [112, 39, 209],
    });
  } else if (name_attribute === 'Medium Tension Towers') {
    return new MVTLayer({
      id: `mvtMediumTensionTowers_${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_medium_tension_towers/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      pickable: true,
      getLineWidth: 2,
      getFillColor: [0, 0, 0, 0],
      getLineColor: [112, 39, 209],
    });
  } else if (name_attribute === 'Trenches') {
    return new MVTLayer({
      id: `mvtTrenches_${id_dataset}_${id_project}`,
      opacity: transparency / 100,
      data: `${MARTIN}/rpc/public.get_trenches/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
      getPointRadius: subimage !== 'DTM' ? 0.7 : 2,
      pickable: true,
      getLineWidth: 0.3,
      getFillColor: [112, 39, 209],
      getLineColor: [112, 39, 209],
    });
  }
};
