import React, { useEffect, useState } from 'react';
import { Typography, Pagination, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  getProjectByUser,
  getNumberPages,
  deleteProject,
} from '../../services/projects';
import ProjectCard from './components/projectCard';
import { ModalCustom } from '../../components/modal';
import ProjectFilters from './components/projectFilters';
import { useProjectsSlice } from '../../slice/projects';
import { useDispatch, useSelector } from 'react-redux';
import { projectsSelector } from '../../slice/selectors';
import './style.scss';

export interface ProjectFiltersState {
  projectName: string;
  orderBy: string;
  desc: boolean;
}

function Projects() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions: projectActions } = useProjectsSlice();

  const { projects, isLoading } = useSelector(projectsSelector);

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [numberPages, setNumberPages] = useState(1);
  const [page, setPage] = useState(1);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [filters, setFilters] = useState<ProjectFiltersState>({
    projectName: '',
    orderBy: 'creation_date',
    desc: true,
  });

  const handleSelectAllProjects = (isChecked: boolean) => {
    if (isChecked) {
      setSelectedProjects(projects.map(project => project.id_project));
    } else {
      setSelectedProjects([]);
    }
  };

  const handleSelectedProjects = (projectId: number) => {
    setSelectedProjects(prevSelected =>
      prevSelected.includes(projectId)
        ? prevSelected.filter(id => id !== projectId)
        : [...prevSelected, projectId],
    );
  };

  const fetchProjects = async (
    page: number,
    projectName?: string,
    orderBy?: string,
    desc?: boolean,
  ) => {
    dispatch(projectActions.fetchProjectsLoading());

    try {
      const numberOfPages = await getNumberPages();
      const proejctsResponse = await getProjectByUser(
        page,
        projectName,
        orderBy,
        desc,
      );

      setNumberPages(numberOfPages.data.number_of_pages);
      dispatch(
        projectActions.fetchProjectsSuccess(proejctsResponse.data.message),
      );
    } catch (err) {
      dispatch(projectActions.fetchProjectsFailure(err));
    }
  };

  const deleteProjectByCheck = async () => {
    try {
      await Promise.all(
        selectedProjects.map(async (projectId: number) => {
          await deleteProject(projectId);
        }),
      );

      dispatch(projectActions.deleteProjects(selectedProjects));
      setSelectedProjects([]);
      setOpenModalDelete(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangePagination = (newPage: number) => {
    setPage(newPage);

    fetchProjects(newPage, filters.projectName, filters.orderBy, filters.desc);
  };

  const handleSearchProject = () => {
    fetchProjects(1, filters.projectName, filters.orderBy, filters.desc);
  };

  useEffect(() => {
    fetchProjects(1, filters.projectName, filters.orderBy, filters.desc);
  }, [filters.orderBy, filters.desc]);

  return (
    <>
      <div className="container">
        <div className="body_container">
          <ProjectFilters
            projects={projects}
            handleSearchProject={handleSearchProject}
            filters={filters}
            setFilters={setFilters}
            handleSelectAllProjects={handleSelectAllProjects}
            setOpenModalDelete={setOpenModalDelete}
            selectedProjects={selectedProjects}
          />

          {isLoading ? (
            <div className="containerLoader">
              <CircularProgress
                sx={{
                  color: '#7024D1',
                }}
                size={70}
              />
            </div>
          ) : (
            projects.map(project => (
              <ProjectCard
                key={project.id_project}
                project={project}
                selectedProjects={selectedProjects}
                handleSelectedProjects={handleSelectedProjects}
              />
            ))
          )}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination
              count={numberPages}
              page={page}
              onChange={(_, newPage) => handleChangePagination(newPage)}
            />
          </div>
        </div>
      </div>

      <ModalCustom
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        content={
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('projects.deleteProject')}
          </Typography>
        }
        accept={deleteProjectByCheck}
      />
    </>
  );
}

export default Projects;
