/* eslint-disable no-undef */
import { Box, Grid, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import "./style.scss";
import { useEffect, useState } from "react";
import UserImageCard from "../../../components/userImageCard";
import Loading from "../../../components/loading";
import { deleteUserImage, getUserImages } from "../../../services/files";

function ImageViewer(props) {
  const { t } = useTranslation();

  const { images, setImages, setLayers, onCardClick, setSnackbarProps, setOpenSnackbar, setOpenLoader } = props;

  const [isLoading, setIsLoading] = useState(false);

  const loadImages = async () => {
    setIsLoading(true);
    const loadedImages = await getUserImages()
      .then((response) => {
        if (response.status === 200) {
          return response.data.message.map((image) => {
            return {
              ...image,
              selected: false,
            };
          });
        } else return null;
      })
      .catch((err) => {
        setSnackbarProps({
          message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
          severity: "error",
        });
        setOpenSnackbar(true);
        return null;
      })
      .finally(() => {
        setIsLoading(false);
      });

    if (loadedImages) {
      setImages(loadedImages);
    }
  };

  useEffect(() => {
    loadImages();

    return () => {
      images.forEach((img) => URL.revokeObjectURL(img.url));

      setImages(
        images.map((img) => {
          return {
            ...img,
            selected: false,
          };
        }),
      );
      setLayers([]);
    };
  }, []);

  const deleteImage = async (image) => {
    setOpenLoader(true);
    await deleteUserImage(image.id)
      .then(async (response) => {
        if (response.status === 200) {
          setSnackbarProps({ message: t("common.genericSuccessMesage"), severity: "success" });
          setOpenSnackbar(true);
          setOpenLoader(false);
          setLayers([]);
          await loadImages();
        }
      })
      .catch((err) => {
        setSnackbarProps({
          message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
          severity: "error",
        });
        setOpenSnackbar(true);
      })
      .finally(() => {
        setOpenLoader(false);
      });
  };

  return (
    <Box sx={{ marginTop: "20px", marginRight: "2px", display: "flex" }}>
      {isLoading ? (
        <Box className="centeredContainer">
          <Loading />
        </Box>
      ) : !images?.length ? (
        <Box className="centeredContainer">
          <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>{t("images.noImagesPlaceholder")}</Typography>
        </Box>
      ) : (
        <Grid container spacing={2}>
          {images.map((image) => {
            return (
              <Grid item key={image.id} xs="auto" style={{ width: 320 }}>
                <UserImageCard image={image} onCardClick={() => onCardClick(image)} onDeleteImage={deleteImage} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Box>
  );
}

export default ImageViewer;
