import { styled } from '@mui/material/styles';

import ButtonCustom from '../../../components/ui/button';
import useStyles from '../../../theme/styles';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { checkAoi } from '../../../services/areaOfInterest';
import { InputImages, Objective } from '../../../models/project';
import { SnackbarProps } from '../../../components/snackbar';

interface Step2Props {
  activeStep: number;
  stepIndex: number;
  objective: Objective[];
  setObjective: (objective: Objective[]) => void;
  btnDrawActive: boolean;
  activeDraw: () => void;
  btnUploadActive: boolean;
  handleUploadFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  geocoderContainerRef;
  features;
  isNextStepBlocked: boolean;
  handleStep: (step: number) => () => void;
  inputImages: InputImages[];
  setInputImages;
  setSnackbarProps: (props: SnackbarProps) => void;
  setOpenSnackbar: (open: boolean) => void;
}

function Step2(props: Step2Props) {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const { t } = useTranslation();

  const {
    activeStep,
    stepIndex,
    objective,
    setObjective,
    btnDrawActive,
    activeDraw,
    btnUploadActive,
    handleUploadFile,
    geocoderContainerRef,
    features,
    isNextStepBlocked,
    handleStep,
    inputImages,
    setInputImages,
    setSnackbarProps,
    setOpenSnackbar,
  } = props;

  const classes = useStyles();

  const hasLidar = () => {
    const hasLidar = objective.reduce((acc: boolean, ob: Objective) => {
      return acc || ob.selectImageType === 'Digital Terrain Models';
    }, false);
    return hasLidar;
  };

  const hasUserImages = () => {
    const hasUserImages = inputImages.reduce(
      (acc: boolean, inputImage: InputImages) => {
        return acc || Object.keys(inputImage).includes('User Images');
      },
      false,
    );
    return hasUserImages;
  };

  const hasPNOA = () => {
    const basemapImages = inputImages.filter((inputImage: InputImages) => {
      return Object.keys(inputImage).includes('basemap');
    });
    const hasPNOA = basemapImages.some(item =>
      item.basemap.some(imageName => imageName.subimageName === 'PNOA'),
    );
    return hasPNOA;
  };

  const handleNextStep = async (nextStep: number) => {
    if (hasUserImages() || hasLidar() || hasPNOA()) {
      await checkAoi(features, inputImages)
        .then(function (response) {
          if (response.status === 200) {
            const availableBaseImages = response.data.message;
            setInputImages(availableBaseImages);
            const newObjective = objective.map((o: Objective, idx: number) => {
              const images = availableBaseImages[idx];
              if (images[o.selectImageType]) {
                if (
                  images[o.selectImageType].filter(
                    img =>
                      img.subimageType == o.selectSubImageType.subimageType,
                  )
                ) {
                  return o;
                }
                return { ...o, selectSubImageType: {} };
              }
              return { ...o, selectImageType: '', selectSubImageType: {} };
            });
            setObjective(newObjective);
            handleStep(nextStep)();
          }
        })
        .catch(function (err) {
          setSnackbarProps({
            message: err.response?.data
              ? err.response.data.message
              : t('common.genericErrorMessage'),
            severity: 'error',
          });
          setOpenSnackbar(true);
        });
    } else {
      handleStep(nextStep)();
    }
  };

  return (
    <div
      className={
        activeStep === stepIndex ? classes.activeStep : classes.disabledStep
      }
    >
      <div className="containerStep2">
        <div>
          <ButtonCustom
            label={t('common.drawButton')}
            className={`${btnDrawActive ? 'buttonStyleColor' : 'buttonStyleColoOutline'}`}
            variant="contained"
            handleClick={activeDraw}
            disabled={activeStep !== stepIndex}
            tooltip={t('newProject.drawTooltip')}
          />
          <ButtonCustom
            label={t('common.uploadButton')}
            className={`${btnUploadActive ? 'buttonStyleColor' : 'buttonStyleColoOutline'}`}
            variant="contained"
            //handleClick={handleOpenModalArea}
            disabled={activeStep !== stepIndex}
            tooltip={t('newProject.uploadTooltip')}
            content={
              <VisuallyHiddenInput
                type="file"
                accept=".kml, .geojson, .shp, .kmz, .dxf"
                onChange={handleUploadFile}
              />
            }
          />
        </div>
        {activeStep !== stepIndex && <div className="divDisable"></div>}
        <div ref={geocoderContainerRef}></div>
      </div>
      <Box sx={{ mb: 2 }}>
        <div className={`${stepIndex !== activeStep ? classes.hideBtn : ''}`}>
          <ButtonCustom
            label={t('common.backButton')}
            startIcon={<ArrowBackIcon />}
            className="backButton"
            handleClick={handleStep(0)}
            tooltip={t('common.backTooltip')}
          />
          <ButtonCustom
            label={t('common.continueButton')}
            className={
              isNextStepBlocked ? 'buttonStyleColorDisable' : 'buttonStyleColor'
            }
            variant="contained"
            handleClick={() => handleNextStep(2)}
            tooltip={t('common.continueTooltip', {
              extraText: t('common.continueImage'),
            })}
            disabled={isNextStepBlocked}
          />
        </div>
      </Box>
    </div>
  );
}

export default Step2;
