import { Grid, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyles from "../../theme/styles";
import { COLOR_SCALES } from "../../constants";
import "./style.scss";

const StyleCard = ({ type, kpisGroups, legendData, checked }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const legendCaptions = {
    Altitude: t("common.metersAvobeSeaLevel"),
    Height: t("common.metersAvobeSeaLevel"),
    "Vegetation height": t("common.metersAvobeSeaLevel"),
    Radiation: t("common.radiationUnits"),
    Aspect: t("common.degrees", { degreeRange: "(0-360º)" }),
    Slope: t("common.degrees", { degreeRange: "(0-90º)" }),
    "Slope (Pitch)": t("common.degrees", { degreeRange: "(0-90º)" }),
    "Vegetation health": t("common.status"),
    Humidity: t("common.status"),
    "Water bodies": t("common.status"),
    "Night time lights": t("common.status"),
    "Earthworks Monitoring": t("common.status"),
  };

  const formatValue = (value, decimals = 0) => {
    if (typeof value === "number") {
      const v = formatNumber(value, decimals).toString();
      const formated = v.replace(".", ",");
      return formated;
    }
    return value;
  };

  const formatNumber = (value, decimals = 2) => {
    if (value === 0) {
      return value;
    } else if (value === 1) {
      return value;
    } else if (value === -1) {
      return value;
    } else {
      return value.toFixed(decimals);
    }
  };

  const renderLegend = (colorValues, values) => {
    return (
      <div className="layout" style={{ display: "table", width: "200px", height: "10px" }}>
        {colorValues.map((element, index) => {
          return (
            <Tooltip title={`${values && values.length > 0 ? values[index] : "--"}`} placement="top-end">
              <div className={classes.legend} style={{ backgroundColor: element }}></div>
            </Tooltip>
          );
        })}
      </div>
    );
  };

  const renderLegendInfo = (checked, values) => {
    return checked ? (
      <Grid container sx={{ m: 0, alignItems: "center" }}>
        <Grid item xs={6}>
          <p style={{ marginTop: 2, marginBottom: 2, fontSize: "12px" }}>
            {values && values.length > 0 ? formatValue(values[0]) : "--"}
          </p>
        </Grid>
        <Grid container item xs={6} sx={{ justifyContent: "end" }}>
          <p style={{ marginTop: 2, marginBottom: 2, fontSize: "12px" }}>
            {values && values.length > 0 ? formatValue(values[values.length - 1]) : "--"}
          </p>
        </Grid>
      </Grid>
    ) : null;
  };

  return (
    <>
      {type === "NDVI" && (
        <div>
          {renderLegend(COLOR_SCALES[type], legendData)}
          {renderLegendInfo(checked, kpisGroups)}
        </div>
      )}
      {Object.keys(legendCaptions).includes(type) && (
        <div>
          <p style={{ fontSize: "12px", margin: "0px" }}>{legendCaptions[type]}</p>
          {renderLegend(COLOR_SCALES[type], legendData)}
          {renderLegendInfo(checked, kpisGroups)}
        </div>
      )}
    </>
  );
};

export default StyleCard;
