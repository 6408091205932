import { ResponseType } from "axios";
import { apiClient } from "./axiosConfig";
import { Data } from "../models/data";
import { apiPaths } from "./config";

// Define tipos para mejorar la claridad y robustez
interface OptionalDatasetParameters {
  dateStart?: string;
  dateEnd?: string;
  cloud?: string;
  minArea?: string;
  spatialScale?: number;
  modelType?: string;
  idImage?: number;
}

export const postCreateDataset = async (
  data: Data,
  idProject: string,
  nameAsset: string,
  nameAttribute: string,
  basemapName: string,
  optionalParameters: OptionalDatasetParameters,
) => {
  const formData = new FormData();

  const { dateStart, dateEnd, cloud, minArea, spatialScale, modelType, idImage } = optionalParameters;

  const rawInputAlgParameters = {
    mask: data,
    resolution: spatialScale ? undefined : 1,
    start_date: dateStart,
    end_date: dateEnd,
    cloud_percentage: cloud,
    spatial_scale: spatialScale,
    minimum_segment_size: minArea,
    model_type: modelType,
    id_image: idImage
  };

  const inputAlgParameters = Object.fromEntries(
    Object.entries(rawInputAlgParameters).filter(([, value]) => value !== undefined),
  );

  formData.append("id_project", idProject);
  formData.append("name_asset", nameAsset);
  formData.append("name_attribute", nameAttribute);
  formData.append("input_alg_parameters", JSON.stringify(inputAlgParameters));

  if (basemapName) {
    formData.append("basemap_name", basemapName);
  }

  return await apiClient.post(`${apiPaths.dataset}/create_dataset`, formData);
};

export const runDataset = async (id_project: number, datasets: any[]) => {
  const formattedDatasets = [];

  for (const dataset of datasets) {
    const formattedDataset: any = {
      id_project: id_project,
      id_dataset: dataset.id_dataset,
      file_name: `${id_project}_${dataset.id_dataset}.tiff`,
    };
    if (dataset.basemap_name) {
      formattedDataset.basemap_name = dataset.basemap_name;
    }
    if (dataset.basemap_name === "DTM") {
      formattedDataset.zoom = "14";
      formattedDataset.file_name = `${id_project}_${dataset.id_dataset}`;
    }
    if (dataset.spatial_scale) {
      formattedDataset.zoom = "15";

      formattedDataset.area_of_interest = `${JSON.stringify(dataset.area)}`;
    } else if (dataset.area) {
      formattedDataset.zoom = "19";

      formattedDataset.area_of_interest = `${JSON.stringify(dataset.area)}`;
    }
    if (dataset.zoom) {
      formattedDataset.zoom = dataset.zoom
    }
    formattedDatasets.push(formattedDataset);
  }
  const formData = new FormData();
  formData.append("id_project", id_project.toString());

  formData.append("datasets", JSON.stringify(formattedDatasets));

  return await apiClient.post(`${apiPaths.dataset}/run_dataset`, formData);
};

export const deleteDataset = async (id_project: number, id_dataset: number) => {
  return await apiClient.delete(`${apiPaths.dataset}/delete_dataset/${id_project}/${id_dataset}`);
};

export const downloadDataset = async (idProject: number, datasets: number[], fileExtension: string) => {
  const responseType: ResponseType = "blob";

  const HEADERS = {
    responseType: responseType,
  };

  const formData = new FormData();
  formData.append("id_project", JSON.stringify(idProject));
  formData.append("datasets", JSON.stringify(datasets));
  formData.append("file_extension", fileExtension);

  return await apiClient.post(
    `${apiPaths.dataset}/download`,
    formData,
    HEADERS,
  );
};

export const downloadAoi = async (userId: number, projectId: number, fileFormat: string) => {
  const responseType: ResponseType = "blob";

  const HEADERS = {
    params: { file_format: fileFormat },
    responseType: responseType,
  };

  return await apiClient.get(`${apiPaths.dataset}/download_aoi/${userId}/${projectId}`, HEADERS);
};

export const editDatasetName = async (idProject: number, idDataset: number, newDatasetName: string) => {
  const formData = new FormData();

  formData.append("dataset_name", newDatasetName);

  return await apiClient.patch(`${apiPaths.dataset}/edit_dataset/${idProject}/${idDataset}`, formData);
};

export const fetchKPISData = async (id_project: number, id_dataset: number) => {
  return await apiClient.get(`${apiPaths.dataset}/kpis/${id_project}/${id_dataset}`);
};
