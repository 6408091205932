import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Step,
  StepButton,
  StepContent,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import useStyles from "../../theme/styles";

import { FlyToInterpolator } from "@deck.gl/core";
import { IconLayer } from "@deck.gl/layers";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CircularProgress } from "@mui/material";
import Modal from "@mui/material/Modal";
import { area, bbox as turfBbox } from "@turf/turf";
import { DrawPolygonMode, EditableGeoJsonLayer, ModifyMode, ViewMode } from "@deck.gl-community/editable-layers";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import AoiBuffer from "../../components/aoiBuffer";
import MapMini from "../../components/mapNewProject/map";
import { ModalCustom } from "../../components/modal";
import Sandbox from "../../components/sandbox";
import ButtonCustom from "../../components/ui/button";
import CustomSnackbar from "../../components/snackbar";
import { getListAlgorithm, getListAsset, getListAtribute } from "../../services/algorithm";
import { useTranslation } from "react-i18next";
import locationMark from "../../img/position.png";
import { Step1, Step2, Step3, Step4, Step5 } from "./step";
import { navigation } from "../../utils/navigation";
import {
  INITIAL_VIEW_STATE,
  ATTRIBUTE_EQUIVALENCE,
  ASSET_EQUIVALENCE,
  INPUT_IMAGES_WITH_CLOUD_COVERAGE,
  INPUT_IMAGES_WITH_START_DATE,
  INPUT_IMAGES_WITH_END_DATE,
  ATTRIBUTES_WITH_AREA,
  ATTRIBUTES_WITH_SPATIAL_SCALE,
  ICON_MAPPING,
  MAX_ATTRIBUTE_AREA,
  initialOption,
  ATTRIBUTE_ZOOM
} from "../../constants/mapOptions";
import "./style.scss";
import { createProject, deleteProject, existsProject, getProjectById } from "../../services/projects";
import { postCreateDataset, runDataset } from "../../services/datasets";
import { postUploadFilesToConvert } from "../../services/files";

function NewProject() {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const idProjectEdit = localStorage.getItem("idProject");

  const [assetList, setAssetList] = useState([]);

  const [objective, setObjective] = useState([initialOption]);
  const maxPossibleAssets = 10;

  const [algorithmList, setAlgorithmList] = useState([]);
  const [attributeListMaestros, setAttributeListMaestros] = useState();
  const [inputImages, setInputImages] = useState([{}]);

  const [openLoader, setOpenLoader] = useState(true);
  const [idProjectSave, setIdprojectSave] = useState(idProjectEdit);

  const [activeStep, setActiveStep] = useState(0);

  const [fullDatasetIdSave, setFullDatasetIdSave] = useState([]);

  const [showCloudCoverageSlider, setShowCloudCoverageSlider] = useState(false);
  const [showStartDateField, setShowStartDateField] = useState(false);
  const [showEndDateField, setShowEndDateField] = useState(false);
  const [showAreaSlider, setShowAreaSlider] = useState(false);
  const [showSpatialScaleSlider, setShowSpatialScaleSlider] = useState(false);

  const [maxValueSliderMinArea, setMaxValueSliderMinArea] = useState(500);
  const [maxLabelValueSliderMinArea, setMaxLabelValueSliderMinArea] = useState("500");

  localStorage.setItem("openPanelLeft", "");

  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");

  const [openNewProjectDialog, setOpenNewProjectDialog] = useState(true);

  const [openExecute, setOpenExecute] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [messageError, setMessageError] = useState("");

  const [openBackConfirmation, setOpenBackConfirmation] = useState(false);

  const [featuresResultSearch, setFeaturesResultSearch] = useState([]);

  const [openModalSaveChanges, setOpenModalSaveChanges] = useState(false);

  const handleCloseLoader = () => setOpenLoader(false);

  const geocoderContainerRef = useRef(null);
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
  const [viewStateBBOX, setViewStateBBOX] = useState("");

  const [features, setFeatures] = useState({
    type: "FeatureCollection",
    features: [],
  });

  const [areaMeasurement, setAreaMeasurement] = useState(null);

  const [btnDrawActive, setBtnDrawActive] = useState(false);
  const [btnUploadActive, setBtnUploadActive] = useState(false);

  const [mode, setMode] = useState(() => ViewMode);
  const [selectedFeIndex, setSelectedFeIndex] = useState([0]);

  const [steps, setSteps] = useState([
    {
      label: t("newProject.step1Title"),
      description: "step1",
      index: 0,
      isNextStepBlocked: true,
    },
    {
      label: t("newProject.step2Title"),
      description: "step2",
      index: 1,
      isNextStepBlocked: true,
    },
    {
      label: t("newProject.step3Title"),
      description: "step3",
      index: 2,
      isNextStepBlocked: true,
    },
    {
      label: t("newProject.step4Title"),
      description: "step4",
      index: 3,
      isNextStepBlocked: false,
    },
    {
      label: t("newProject.step5Title"),
      description: "step5",
      index: 4,
      isNextStepBlocked: false,
    },
  ]);

  useEffect(() => {
    const translatedSteps = steps.map((step) => {
      const customStep = { ...step, label: t(`newProject.${step.description}Title`) };
      return customStep;
    });
    setSteps(translatedSteps);
    if (assetList) {
      const translatedAssetList = assetList.map((asset) => {
        const customAsset = { ...asset, name_asset: t(`asset.${asset.id_algorithm_asset}`) };
        return customAsset;
      });
      setAssetList(translatedAssetList);
    }
    if (attributeListMaestros) {
      const translatedAttributeList = attributeListMaestros.map((attribute) => {
        const customAttribute = { ...attribute, name_attribute: t(`attribute.${attribute.id_algorithm_attribute}`) };
        return customAttribute;
      });
      setAttributeListMaestros(translatedAttributeList);
    }
  }, [i18n.language]);

  const handleChangeName = (event) => {
    setProjectName(event.target.value);
  };

  useEffect(() => {
    setOpenLoader(true);

    const getAlgorithm = async () => {
      await getListAsset()
        .then(function (response) {
          if (response.status === 200) {
            const originalAssetList = response.data.message;
            const translatedAssetList = originalAssetList.map((asset) => {
              const customAsset = { ...asset, name_asset: t(`asset.${asset.id_algorithm_asset}`) };
              return customAsset;
            });
            setAssetList(translatedAssetList);
          }
        })
        .catch(function (err) {
          setSnackbarProps({
            message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
            severity: "error",
          });
          setOpenSnackbar(true);
        });

      await getListAtribute()
        .then(function (response) {
          if (response.status === 200) {
            const originalAttributeList = response.data.message;
            const translatedAttributeList = originalAttributeList.map((attribute) => {
              const customAttribute = {
                ...attribute,
                name_attribute: t(`attribute.${attribute.id_algorithm_attribute}`),
              };
              return customAttribute;
            });
            setAttributeListMaestros(translatedAttributeList);
          }
        })
        .catch(function (err) {
          setSnackbarProps({
            message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
            severity: "error",
          });
          setOpenSnackbar(true);
        });

      await getListAlgorithm()
        .then(function (response) {
          if (response.status === 200) {
            setAlgorithmList(response.data.message);
          }
        })
        .catch(function (err) {
          setSnackbarProps({
            message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
            severity: "error",
          });
          setOpenSnackbar(true);
        });

      if (idProjectSave) {
        await getProjectById(idProjectSave)
          .then(function (projectEditResponse) {
            if (projectEditResponse.status === 200) {
              if (projectEditResponse.data.message.length > 0) {
                const newObjectives = projectEditResponse.data.message.map((element) => {
                  return {
                    ...initialOption,
                    asset: assetList.filter((asset) => asset.name_asset === element.name_asset)[0].id_algorithm_asset,
                    attribute: attributeListMaestros.filter(
                      (attribute) => attribute.name_attribute === element.name_attribute,
                    )[0].id_algorithm_attribute,
                  };
                });

                setObjective(newObjectives);
              }
            }
          })
          .catch(function (err) {
            setSnackbarProps({
              message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
              severity: "error",
            });
            setOpenSnackbar(true);
          });
      }
      setOpenLoader(false);
    };

    getAlgorithm();
  }, []);

  const handleClickBack = () => {
    history.push(navigation.projects);
  };

  const handleClickSaveProject = async () => {
    setOpenLoader(true);
    const datasetIds = [];
    try {
      const createProjectResponse = await createProject(projectName, projectDescription);
      if (createProjectResponse.status === 200) {
        const projectId = createProjectResponse.data.message.id_project;

        for (const option of objective) {
          const attributeName = ATTRIBUTE_EQUIVALENCE[option.attribute];
          const assetName = ASSET_EQUIVALENCE[option.asset];

          const basemapName = option.selectImageType.toLowerCase() == "user images" ? 'user-image' : option.selectSubImageType.subimageName;

          const optionalParameters = {
            dateStart: undefined,
            dateEnd: undefined,
            cloud: undefined,
            minArea: undefined,
            spatialScale: undefined,
            modelType: undefined,
          };

          if (basemapName === "LIDAR-DTM") {
            optionalParameters.modelType = "dtm";
          }
          else if (basemapName === "user-image") {
            optionalParameters.idImage = option.selectSubImageType.id;
          }

          if (INPUT_IMAGES_WITH_CLOUD_COVERAGE.includes(option.selectImageType)) {
            const cloud = option.cloudCoverage;
            optionalParameters.cloud = cloud;
          }

          if (INPUT_IMAGES_WITH_START_DATE.includes(option.selectImageType)) {
            const dateStart = new Date(option.dateStart);

            const z = dateStart.getTimezoneOffset() * 60 * 1000;
            const localDateStart = new Date(dateStart - z);

            optionalParameters.dateStart = localDateStart.toISOString().split("T")[0].replaceAll("-", "");
          }

          if (INPUT_IMAGES_WITH_END_DATE.includes(option.selectImageType)) {
            const dateEnd = new Date(option.dateEnd);

            const z = dateEnd.getTimezoneOffset() * 60 * 1000;
            const localDateEnd = new Date(dateEnd - z);

            optionalParameters.dateEnd = localDateEnd.toISOString().split("T")[0].replaceAll("-", "");
          }

          if (attributeName === "Watersheds" || attributeName === "Building Footprints") {
            const minArea = option.minArea;
            optionalParameters.minArea = minArea;
          } else if (attributeName === "Segmentation") {
            const minArea = option.minArea;
            const spatialScale = option.spatialScale;
            optionalParameters.minArea = minArea;
            optionalParameters.spatialScale = spatialScale;
          } else if (attributeName === "Contour Lines") {
            const spatialScale = option.spatialScale;
            optionalParameters.spatialScale = spatialScale;
          }

          //optionalParameters.modelType = option.modelType;

          const continueCreation = await postCreateDataset(
            features,
            projectId,
            assetName,
            attributeName,
            basemapName,
            optionalParameters,
          )
            .then(function (response) {
              setIdprojectSave(projectId);
              datasetIds.push(response.data.message.id_dataset);
              return true;
            })
            .catch(async function (err) {
              setOpenLoader(false);
              setOpenModalSaveChanges(false);
              setMessageError(err.response.data.message);
              setOpenModalError(true);
              await deleteProject(projectId).catch((err) => {
                setSnackbarProps({
                  message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
                  severity: "error",
                });
                setOpenSnackbar(true);
              });
              return false;
            });
          if (!continueCreation) break;
        }
      }
    } catch (error) {
      setOpenLoader(false);
      setOpenModalSaveChanges(false);
      setMessageError(error.response?.data?.message);
      setOpenModalError(true);
    } finally {
      setOpenLoader(false);
      setOpenModalSaveChanges(false);
      setFullDatasetIdSave(datasetIds);
    }
  };

  const handleStep = (step) => () => {
    setActiveStep(step);

    const showCloudCoverage = !!objective.find((o) => INPUT_IMAGES_WITH_CLOUD_COVERAGE.includes(o.selectImageType));
    const showStartDate = !!objective.find((o) => INPUT_IMAGES_WITH_START_DATE.includes(o.selectImageType));
    const showEndDate = !!objective.find((o) => INPUT_IMAGES_WITH_END_DATE.includes(o.selectImageType));
    const showArea = !!objective.find((o) => ATTRIBUTES_WITH_AREA.includes(ATTRIBUTE_EQUIVALENCE[o.attribute]));
    const showSpatialScale = !!objective.find((o) =>
      ATTRIBUTES_WITH_SPATIAL_SCALE.includes(ATTRIBUTE_EQUIVALENCE[o.attribute]),
    );

    const maxAreaSliderValue = Math.min(
      ...objective
        .filter((o) => ATTRIBUTES_WITH_AREA.includes(ATTRIBUTE_EQUIVALENCE[o.attribute]))
        .map((o) => MAX_ATTRIBUTE_AREA[ATTRIBUTE_EQUIVALENCE[o.attribute]]),
    );

    setShowCloudCoverageSlider(showCloudCoverage);
    setShowStartDateField(showStartDate);
    setShowEndDateField(showEndDate);
    setShowAreaSlider(showArea);
    setShowSpatialScaleSlider(showSpatialScale);
    setMaxValueSliderMinArea(maxAreaSliderValue);
    setMaxLabelValueSliderMinArea(maxAreaSliderValue.toString());
  };

  useEffect(() => {
    if (features.features.length > 0) {
      const ar = area(features);
      setAreaMeasurement(`${(ar * 0.000001).toFixed(4)}`);
    } else {
      setAreaMeasurement("");
    }

    const newSteps = steps.map((step) => {
      if (step.description == "step2") {
        return {
          ...step,
          isNextStepBlocked: features.features.length == 0,
        };
      }
      return step;
    });
    setSteps(newSteps);
  }, [features]);

  const setNextStepBlocked = (stepsStatus) => {
    const newSteps = steps.map((s) => {
      if (stepsStatus[s.index] !== undefined) {
        return { ...s, isNextStepBlocked: stepsStatus[s.index] };
      }
      return s;
    });
    setSteps(newSteps);
  };

  useEffect(() => {
    // Step 1
    const isBlockedStep1 = objective.reduce((acc, obj) => {
      acc = acc || obj.attribute === null;
      return acc;
    }, false);

    // Step 3
    const isBlockedStep2 = objective.reduce((acc, obj) => {
      acc = acc || obj.selectSubImageType === "" || obj.selectImageType === "";
      return acc;
    }, false);

    setNextStepBlocked({ 0: isBlockedStep1, 2: isBlockedStep2 });
  }, [objective]);

  useEffect(() => {
    if (viewStateBBOX !== "" && viewStateBBOX !== undefined) {
      if (viewStateBBOX.length === 0) {
        setFeaturesResultSearch([
          {
            coordinates: [0, 0],
          },
        ]);
      } else {
        setViewState({
          longitude: viewStateBBOX[0],
          latitude: viewStateBBOX[1],
          zoom: 17,
          pitch: 0,
          bearing: 0,
          transitionDuration: 2000,
          transitionInterpolator: new FlyToInterpolator(),
        });

        setFeaturesResultSearch([
          {
            coordinates: [viewStateBBOX[0], viewStateBBOX[1]],
          },
        ]);
      }
    }
  }, [viewStateBBOX]);

  const layerEdit = new EditableGeoJsonLayer({
    data: features,
    mode,
    pickable: true,
    selectedFeatureIndexes: selectedFeIndex,
    autoHighlight: true,
    onEdit: ({ updatedData, editType }) => {
      if (editType === "addFeature") {
        setMode(() => ModifyMode);
        setBtnDrawActive(false);
        let arraySelectedFeature = [];
        updatedData.features.forEach((f, index) => {
          arraySelectedFeature.push(index);
        });
        setSelectedFeIndex(arraySelectedFeature);
      }
      setFeatures(updatedData);
    },
    onClick: () => {
      //setSelectedFeIndex((prevState)=>[...prevState, info.index]);
      //setMode(ModifyMode);
    },
    getFillColor: () => [112, 39, 209, 50],
    getLineColor: () => [112, 39, 209],
    highlightColor: () => [112, 39, 209, 50],
    getEditHandlePointColor: () => [112, 39, 209],
    getEditHandlePointOutlineColor: () => [175, 155, 201],
  });

  const layerResultSearch = new IconLayer({
    id: "icon-layer-result-search",
    data: featuresResultSearch,
    pickable: true,
    iconAtlas: locationMark,
    iconMapping: ICON_MAPPING,
    getIcon: () => "marker",
    sizeScale: 6,
    getPosition: (d) => {
      return d.coordinates;
    },
    getSize: () => 6,
    getColor: [112, 36, 209],
  });

  const activeDraw = () => {
    if (!btnDrawActive) {
      setFeatures({
        type: "FeatureCollection",
        features: [],
      });
    }
    setMode(() => (btnDrawActive ? ViewMode : DrawPolygonMode));
    setBtnDrawActive(!btnDrawActive);
    setBtnUploadActive(false);
  };

  const removeDraw = () => {
    setFeatures({
      type: "FeatureCollection",
      features: [],
    });
    setBtnDrawActive(false);
  };

  const [openSandbox] = useState(false);

  const [snackbarProps, setSnackbarProps] = useState({ message: "", severity: "" });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleExecuteDataset = async () => {
    setOpenExecute(true);
    const datasetsToSend = [];

    for (let index = 0; index < fullDatasetIdSave.length; index++) {
      const datasetId = fullDatasetIdSave[index];
      const basemapName = objective[index].selectImageType.toLowerCase() == "user images" ?
        'user-image' :
        objective[index].selectSubImageType.subimageName;

      const optionalParameters = {
        area: undefined,
        spatial_scale: false,
      };

      const attributeName = ATTRIBUTE_EQUIVALENCE[objective[index].attribute];
      if (
        attributeName === "Solar Panels" ||
        attributeName === "Powerblocks" ||
        attributeName === "Roads (Solar Plants)" ||
        attributeName === "Solar Panels Plants" ||
        attributeName === "Solar Trackers Plants" ||
        attributeName === "Wind Turbines" ||
        attributeName === "Vehicles" ||
        attributeName === "Roads" ||
        attributeName === "High Tension Towers" ||
        attributeName === "Medium Tension Towers" ||
        attributeName === "Trenches" ||
        attributeName === "Individual Trees" ||
        attributeName === "Pipes"
      ) {
        //send params for download image first
        optionalParameters.area = features;
      } else if (attributeName === "Segmentation") {
        //send params for download image first
        optionalParameters.area = features;
        optionalParameters.spatial_scale = true;
      }

      optionalParameters.zoom = ATTRIBUTE_ZOOM[attributeName]

      const datasetToSend = { id_dataset: datasetId, basemap_name: basemapName, ...optionalParameters };
      datasetsToSend.push(datasetToSend);
    }
    runDataset(idProjectSave, datasetsToSend).catch((err) => {
      setSnackbarProps({
        message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
        severity: "error",
      });
      setOpenSnackbar(true);
    });
  };

  const handleUploadFile = async (e) => {
    await postUploadFilesToConvert(e.nativeEvent.target.files[0])
      .then(function (response) {
        setBtnUploadActive(true);
        setBtnDrawActive(false);
        setFeatures(response.data.geojson);

        const bbox = turfBbox(response.data.geojson);
        setViewStateBBOX(bbox);
      })
      .catch(async function () {
        setSnackbarProps({ message: t("newProject.uploadFileError"), severity: "error" });
        setOpenSnackbar(true);
      });
  };

  const handleSumbmitNewProjectDialog = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());

    const projectName = formJson.projectName;

    const createProjectResponse = await existsProject(projectName);
    if (createProjectResponse?.status === 200) {
      const existsProject = createProjectResponse.data.message;
      if (!existsProject) {
        setProjectName(projectName);
        setOpenNewProjectDialog(false);
      } else {
        setSnackbarProps({ message: t("newProject.projectNameExists"), severity: "error" });
        setOpenSnackbar(true);
      }
    } else {
      setSnackbarProps({ message: t("common.genericErrorMessage"), severity: "error" });
      setOpenSnackbar(true);
    }
  };

  const handleCancelNewProjectDialog = () => {
    history.push(navigation.projects);
  };

  const getContent = (step) => {
    const stepName = step.description;
    const stepIndex = step.index;
    switch (stepName) {
      case "step1":
        return (
          <Step1
            className={activeStep === stepIndex ? classes.activeStep : classes.disabledStep}
            objective={objective}
            setObjective={setObjective}
            maxPossibleAssets={maxPossibleAssets}
            activeStep={activeStep}
            stepIndex={stepIndex}
            isNextStepBlocked={step.isNextStepBlocked}
            algorithmList={algorithmList}
            attributeListMaestros={attributeListMaestros}
            assetList={assetList}
            inputImages={inputImages}
            setInputImages={setInputImages}
            handleStep={handleStep}
          />
        );
      case "step2":
        return (
          <Step2
            activeStep={activeStep}
            stepIndex={stepIndex}
            isNextStepBlocked={step.isNextStepBlocked}
            objective={objective}
            setObjective={setObjective}
            btnDrawActive={btnDrawActive}
            activeDraw={activeDraw}
            btnUploadActive={btnUploadActive}
            handleUploadFile={handleUploadFile}
            geocoderContainerRef={geocoderContainerRef}
            handleStep={handleStep}
            features={features}
            inputImages={inputImages}
            setInputImages={setInputImages}
            setSnackbarProps={setSnackbarProps}
            setOpenSnackbar={setOpenSnackbar}
          />
        );
      case "step3":
        return (
          <Step3
            activeStep={activeStep}
            stepIndex={stepIndex}
            isNextStepBlocked={step.isNextStepBlocked}
            objective={objective}
            setObjective={setObjective}
            inputImages={inputImages}
            handleStep={handleStep}
          />
        );
      case "step4":
        return (
          <Step4
            activeStep={activeStep}
            stepIndex={stepIndex}
            isNextStepBlocked={step.isNextStepBlocked}
            objective={objective}
            setObjective={setObjective}
            maxValueSliderMinArea={maxValueSliderMinArea}
            maxLabelValueSliderMinArea={maxLabelValueSliderMinArea}
            showAreaSlider={showAreaSlider}
            showCloudCoverageSlider={showCloudCoverageSlider}
            showEndDateField={showEndDateField}
            showStartDateField={showStartDateField}
            showSpatialScaleSlider={showSpatialScaleSlider}
            handleStep={handleStep}
          />
        );
      case "step5":
        return (
          <Step5
            className={activeStep === stepIndex ? classes.activeStep : classes.disabledStep}
            activeStep={activeStep}
            stepIndex={stepIndex}
            projectDescription={projectDescription}
            setProjectDescription={setProjectDescription}
            handleStep={handleStep}
          />
        );
    }
  };

  return (
    <div className="container">
      <Modal
        open={openLoader}
        onClose={handleCloseLoader}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="containerLoader">
          <CircularProgress
            sx={{
              color: "#7024D1",
            }}
            size={70}
          />
        </div>
      </Modal>
      <div className="body_container_menu">
        <ButtonCustom
          className={"backButton"}
          handleClick={() => {
            setOpenBackConfirmation(true);
          }}
          startIcon={<ArrowBackIcon />}
          tooltip={t("newProject.backToProjects")}
          label={t("common.cancelButton")}
        />
        <ModalCustom
          open={openBackConfirmation}
          setOpen={setOpenBackConfirmation}
          content={
            <Stack spacing={2}>
              <div>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  {t("newProject.backConfirmationTitle")}
                </Typography>
                <Typography>{t("newProject.backConfirmationSubtitle")}</Typography>
              </div>
            </Stack>
          }
          accept={handleClickBack}
          hasButton={true}
        />
        <TextField
          variant="standard"
          className={`select_white ${classes.colorTextField}`}
          value={projectName}
          label={t("newProject.nameInputLabel")}
          onChange={handleChangeName}
          style={{ fontSize: "12px" }}
        />
      </div>
      <div className="body_container_newA">
        <div className="body_container_newA_div" style={{ width: "50%", height: "70vh", overflowY: "hidden" }}>
          <div className="body_container_newA_div_opt">
            <Typography sx={{ width: "33%", flexShrink: 0 }}>{t("newProject.workflowTitle")}</Typography>
          </div>
          {!openSandbox ? (
            <Box sx={{ display: "flex", height: "67vh", overflowY: "auto" }}>
              <Box className={classes.stepperDiv}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label} expanded={true} className={classes.step}>
                      <StepButton color="inherit" onClick={handleStep(index)} className={classes.cursorBtn}>
                        {step.label}
                      </StepButton>
                      <StepContent>{getContent(step)}</StepContent>
                    </Step>
                  ))}
                </Stepper>
                <div className="body_container_footer">
                  <ButtonCustom
                    label={t("common.saveButton")}
                    className={`${!!objective[0].asset && !!projectName && features.features.length > 0
                      ? "buttonStyleColor"
                      : "buttonStyleColoOutline"
                      }`}
                    variant="contained"
                    handleClick={handleClickSaveProject}
                    disabled={activeStep === "" || projectName === "" || features.features.length === 0}
                    tooltip={t("common.saveButton")}
                  />
                  <ModalCustom
                    open={openModalSaveChanges}
                    setOpen={setOpenModalSaveChanges}
                    content={
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t("newProject.saveConfirmationTitle")}
                      </Typography>
                    }
                    accept={handleClickSaveProject}
                  />
                  <ButtonCustom
                    label={t("common.executeButton")}
                    className={`${idProjectSave !== null ? "buttonStyleColor" : "buttonStyleColoOutline"}`}
                    variant="contained"
                    handleClick={handleExecuteDataset}
                    disabled={idProjectSave === null || !fullDatasetIdSave}
                    tooltip={t("common.executeProjectTooltip")}
                  />
                  <ModalCustom
                    open={openExecute}
                    setOpen={setOpenExecute}
                    content={
                      <div>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          {t("projects.successExecuteProject")}
                        </Typography>
                        <ButtonCustom
                          label={t("newProject.backProjectButton")}
                          className="buttonStyleColor"
                          variant="contained"
                          handleClick={() => history.push(navigation.projects)}
                          tooltip={t("newProject.backProjectButton")}
                        />
                        <ButtonCustom
                          label={t("newProject.newProject")}
                          className="buttonStyleColor"
                          variant="contained"
                          handleClick={() => window.location.reload()}
                          tooltip={t("newProject.newProject")}
                        />
                      </div>
                    }
                    hasButton={false}
                  />
                  <ModalCustom
                    open={openModalError}
                    setOpen={setOpenModalError}
                    content={
                      <div>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          {messageError}
                        </Typography>
                      </div>
                    }
                    hasButton={false}
                  />
                  <CustomSnackbar
                    snackbarProps={snackbarProps}
                    openSnackbar={openSnackbar}
                    setOpenSnackbar={setOpenSnackbar}
                  />
                </div>
              </Box>
            </Box>
          ) : (
            <>
              <Box className={classes.sandBoxDiv}>
                <Sandbox />
              </Box>
              <ButtonCustom label="Import" className="buttonStyleColoOutline" variant="contained" tooltip="Import" />
              <ButtonCustom
                label="Download"
                className="buttonStyleColoOutline"
                variant="contained"
                tooltip="Download"
              />
            </>
          )}
        </div>
        <Divider orientation="vertical" flexItem style={{ height: "80vh", left: "50%" }} />
        <div className="body_container_newA_div" style={{ width: "40%", position: "fixed", right: "5%" }}>
          <Typography sx={{ width: "33%", flexShrink: 0 }}>{t("common.preview")}</Typography>
          <div>
            <Stack direction="row" spacing={1}>
              <ButtonCustom
                label={t("newProject.removeAreaButton")}
                className={`${features.features.length > 0 ? "buttonStyleColor" : "buttonStyleColoOutline"}`}
                handleClick={removeDraw}
                disabled={features.features.length === 0}
                tooltip={t("newProject.removeAreaTooltip")}
              />
              {features.features.length ? (
                <div>
                  <AoiBuffer
                    features={features}
                    setFeatures={setFeatures}
                    setOpenSnackbar={setOpenSnackbar}
                    setSnackbarProps={setSnackbarProps}
                  />
                </div>
              ) : null}
            </Stack>
          </div>
          <MapMini
            layersArray={[layerEdit, layerResultSearch]}
            btnDrawActive={btnDrawActive}
            viewState={viewState}
            setViewState={setViewState}
            viewStateBBOX={viewStateBBOX}
            setViewStateBBOX={setViewStateBBOX}
            measurement={areaMeasurement}
            features={features}
          />
        </div>
      </div>
      <Dialog
        open={openNewProjectDialog}
        onClose={(e) => e.preventDefault()}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            handleSumbmitNewProjectDialog(event);
          },
        }}
      >
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <Typography id="modal-modal-title" className="modal-title" variant="h6" component="h2">
            {t("newProject.nameTitleModal")}:
          </Typography>
          <TextField id="project-name" label={t("newProject.nameInputLabel")} name="projectName" autoFocus required />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelNewProjectDialog}>{t("common.cancelButton")}</Button>
          <Button type="submit">{t("common.createButton")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default NewProject;
