import { apiClient } from "./axiosConfig";
import { apiPaths } from "./config";

export const getListAtribute = async () => {
  return await apiClient.get(`${apiPaths.algorithmAttribute}/algorithm_attribute`);
};

export const getListAsset = async () => {
  return await apiClient.get(`${apiPaths.algorithmAsset}/algorithm_asset`);
};

export const getListAlgorithm = async () => {
  return await apiClient.get(`${apiPaths.algorithm}/algorithm`);
};
