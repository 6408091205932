import React, { useState } from "react";
import ButtonCustom from "../../components/ui/button";
import useStyles from "../../theme/styles";
import {
  Typography,
  Card,
  CardContent,
  InputAdornment,
  FilledInput,
  CardMedia,
  CardActions,
  IconButton,
  CardHeader,
  Menu,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CachedIcon from "@mui/icons-material/Cached";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { dataImageCatalog, dataImageCatalogFavourite } from "./constants";
import "./style.scss";

function Catalog() {
  const classes = useStyles();
  localStorage.setItem("openPanelLeft", "");
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <div className="container">
      <div className={classes.headerTitle}>
        <div>
          <FilledInput
            value="Search image"
            id="outlined-start-adornment"
            endAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            size="small"
            className={classes.inputWhite}
          />
          <ButtonCustom label="New Image" className="buttonStyleColor" variant="contained" />
        </div>
      </div>
      <Typography sx={{ fontSize: 20, width: "33%", flexShrink: 0 }} className={classes.title}>
        Favourites
      </Typography>
      <div className={classes.headerContent}>
        {Object.values(dataImageCatalogFavourite).map(({ title, content, date, times, imgUrl }, index) => {
          return (
            <Card sx={{ maxWidth: 345 }} key={index}>
              <StarIcon className={classes.iconFavo} />
              <CardMedia component="img" height="200" image={imgUrl} alt="img " />
              <CardHeader
                action={
                  <IconButton
                    aria-label="more"
                    onClick={handleClick}
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
                title={title}
              />
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                disableScrollLock={true}
              >
                <MenuItem onClick={handleClose}>
                  <StarBorderIcon className={classes.marginRight} />
                  <Typography variant="inherit">Favorite</Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <DeleteOutlineIcon className={classes.marginRight} />
                  <Typography variant="inherit">Map view</Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <RemoveRedEyeOutlinedIcon className={classes.marginRight} />
                  <Typography variant="inherit">Delete</Typography>
                </MenuItem>
              </Menu>
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {content}
                </Typography>
              </CardContent>
              <CardActions>
                <CalendarTodayIcon fontSize="small" className={classes.marginRight} />
                <Typography variant="body2" color="text.secondary">
                  {date}
                </Typography>
                <CachedIcon fontSize="small" />
                <Typography variant="body2" color="text.secondary">
                  {times}
                </Typography>
              </CardActions>
            </Card>
          );
        })}
      </div>
      <Typography sx={{ fontSize: 20, width: "33%", flexShrink: 0 }} className={classes.title}>
        Images
      </Typography>
      <div className={classes.headerContent}>
        {Object.values(dataImageCatalog).map(({ title, content, date, times, imgUrl }, index) => {
          return (
            <Card sx={{ maxWidth: 345 }} key={index}>
              <StarBorderIcon className={classes.iconNoFavo} />
              <CardMedia component="img" height="200" image={imgUrl} alt="img " />
              <CardHeader
                action={
                  <IconButton
                    aria-label="more"
                    onClick={handleClick}
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
                title={title}
              />
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                disableScrollLock={true}
              >
                <MenuItem onClick={handleClose}>
                  <StarBorderIcon className={classes.marginRight} />
                  <Typography variant="inherit">Favorite</Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <DeleteOutlineIcon className={classes.marginRight} />
                  <Typography variant="inherit">Map view</Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <RemoveRedEyeOutlinedIcon className={classes.marginRight} />
                  <Typography variant="inherit">Delete</Typography>
                </MenuItem>
              </Menu>
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {content}
                </Typography>
              </CardContent>
              <CardActions>
                <CalendarTodayIcon fontSize="small" className={classes.marginRight} />
                <Typography variant="body2" color="text.secondary">
                  {date}
                </Typography>
                <CachedIcon fontSize="small" />
                <Typography variant="body2" color="text.secondary">
                  {times}
                </Typography>
              </CardActions>
            </Card>
          );
        })}
      </div>
      <div className={classes.pageTitle}></div>
      <div className={classes.pageContent}></div>
    </div>
  );
}

export default Catalog;
