import { z } from 'zod';

export const NodeEnvironmentsSchema = z.enum([
  'development',
  'production',
  'test',
]);

const configSchema = z.object({
  NODE_ENV: z
    .enum(['development', 'production', 'test'])
    .default(NodeEnvironmentsSchema.Values.development),
  SERVER_API_DOTGIS: z.string().url(),
  MARTIN: z.string().url(),
  MAPBOX_ACCESS_TOKEN: z.string(),
  GOOGLE_MAPS_API_KEY: z
    .string()
    .default('AIzaSyABSeraVnafiZnJx9MUCe4Dwy2_XRZy96Q'),
  GENERATE_SOURCEMAP: z.boolean().default(false),
  TILE_SERVER: z.string().url(),
});

export const {
  NODE_ENV,
  SERVER_API_DOTGIS,
  MARTIN,
  MAPBOX_ACCESS_TOKEN,
  GOOGLE_MAPS_API_KEY,
  TILE_SERVER
} = configSchema.parse({
  NODE_ENV: process.env.NODE_ENV,
  SERVER_API_DOTGIS: process.env.REACT_APP_SERVER_API_DOTGIS,
  MARTIN: process.env.REACT_APP_MARTIN,
  MAPBOX_ACCESS_TOKEN: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  TILE_SERVER: process.env.REACT_APP_TILE_SERVER,
});
