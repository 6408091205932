import './App.css';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import Login from '../src/pages/login';
import Catalog from '../src/pages/imageCatalog';
import Dashboard from '../src/pages/dashboardResults';
import Projects from '../src/pages/projects';
import NewProject from '../src/pages/newProject';
import UserProfile from '../src/pages/user';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//import theme from "./theme/theme"
import PrivateRoute from './pages/types';
import UserImages from './pages/images';
import DefaultLayout from './layout/DefaultLayout';
import { navigation } from './utils/navigation';

function App() {
  const theme = createTheme({
    palette: {
      secondary: {
        main: '#E33E7F',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            minWidth: 'auto',
            backgroundColor: '#ffffff',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            backgroundColor: '#ffffff',
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            backgroundColor: '#ffffff',
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path={navigation.login}>
            <Login />
          </Route>
          <DefaultLayout>
            <PrivateRoute
              exact
              path={navigation.imageCatalog}
              component={Catalog}
            />
            <PrivateRoute
              exact
              path={navigation.projects}
              component={Projects}
            />
            <PrivateRoute
              exact
              path={navigation.dashboardResults}
              component={Dashboard}
            />
            <PrivateRoute
              exact
              path={navigation.images}
              component={UserImages}
            />
            <PrivateRoute
              exact
              path={navigation.newProject}
              component={NewProject}
            />
            <PrivateRoute
              exact
              path={navigation.profile}
              component={UserProfile}
            />
          </DefaultLayout>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
