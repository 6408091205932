import { useEffect, useState, useRef } from 'react';
import DeckGL from '@deck.gl/react';
import { StaticMap, NavigationControl, MapContext } from 'react-map-gl';
import { FlyToInterpolator, WebMercatorViewport } from '@deck.gl/core';
import SearchMap from '../../components/searchMap';
import { MAPBOX_ACCESS_TOKEN } from '../../config/env';
import BasemapWidget from '../basemapWidget';
import './style.scss';

const MAPBOX_STYLE = 'mapbox://styles/mapbox/satellite-streets-v11';

function MapMini(props) {
  const {
    layersArray,
    btnDrawActive,
    viewState,
    setViewState,
    viewStateBBOX,
    setViewStateBBOX,
    measurement,
    features,
  } = props;

  const [basemapLayers, setBasemapLayers] = useState([]);
  const mapContainerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (entries[0]) {
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      }
    });

    if (mapContainerRef.current) {
      resizeObserver.observe(mapContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const onViewStateChange = ({ viewState: newViewState }) => {
    setViewState(newViewState);
  };

  useEffect(() => {
    if (viewStateBBOX !== '' && viewStateBBOX !== undefined) {
      if (viewStateBBOX.length > 0 && dimensions.width > 0 && dimensions.height > 0) {
        const viewport = new WebMercatorViewport({
          width: dimensions.width,
          height: dimensions.height,
        });
        const { longitude, latitude, zoom } = viewport.fitBounds(
          [
            [viewStateBBOX[0], viewStateBBOX[1]],
            [viewStateBBOX[2], viewStateBBOX[3]],
          ],
          { padding: 20 }
        );
        setViewState({
          longitude,
          latitude,
          zoom,
          pitch: 0,
          bearing: 0,
          transitionDuration: 2000,
          transitionInterpolator: new FlyToInterpolator(),
        });
      }
    }
  }, [viewStateBBOX]);

  const drawSvgIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" height="15" width="15" viewBox="0 0 512 512">
      <path fill="#FFF" d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/>
    </svg>
  `;

  const getCursorType = () => {
    if (btnDrawActive) {
      return `url("data:image/svg+xml;base64,${btoa(drawSvgIcon)}") 0 15, auto`;
    }
    return features && features.features.length > 0 ? 'pointer' : 'default';
  };

  return (
    <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }}>
      <DeckGL
        viewState={viewState}
        controller={{
          doubleClickZoom: false,
        }}
        layers={[...basemapLayers, ...layersArray]}
        onViewStateChange={onViewStateChange}
        getCursor={() => getCursorType()}
        ContextProvider={MapContext.Provider}
      >
        <div className="container-btn-map-zoom">
          <NavigationControl position="bottom-left" showCompass={false} />
        </div>
        <StaticMap
          mapStyle={MAPBOX_STYLE}
          mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
        />

        <div className="widget-container">
          <BasemapWidget
            layersArray={basemapLayers}
            setLayersArray={setBasemapLayers}
            rightCorner
            areaOfInterest={features}
          />
          <SearchMap changeViewport={setViewStateBBOX} />
        </div>

        {measurement ? (
          <div className="container-btn-map-measure">
            AOI: {measurement} km<sup>2</sup>
          </div>
        ) : null}
      </DeckGL>
    </div>
  );
}

export default MapMini;
