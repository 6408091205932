import { apiClient } from "./axiosConfig";
import { apiPaths } from "./config";

export const postUploadFilesToConvert = async (file: string) => {
  const formData = new FormData();
  formData.append("file", file);

  return await apiClient.post(`${apiPaths.files}/upload`, formData, {
    headers: {
      contentType: "multipart/form-data",
    },
  });
};

export const uploadUserImage = async (
  fileName: string,
  imageDate: string,
  imageType: string,
  file: string,
  description?: string,
) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", fileName);
  formData.append("date", imageDate);
  formData.append("type", imageType);
  formData.append("description", description);

  return await apiClient.post(`${apiPaths.files}/user_images`, formData, {
    headers: {
      contentType: "multipart/form-data",
    },
  });
};

export const getUserImages = async () => {
  return await apiClient.get(`${apiPaths.files}/user_images`);
};

export const getAuthenticatedImage = async (imageId: number) => {
  return await apiClient.get(`${apiPaths.files}/authenticated_images/${imageId}`);
};

export const deleteUserImage = async (idImage: number) => {
  return await apiClient.delete(`${apiPaths.files}/user_images/${idImage}`);
};

export const getNormalizedTiff = async (file: File) => {  
  const formData = new FormData();
  formData.append("file", file);

  return await apiClient.post(`${apiPaths.files}/normalize_tiff`, formData, {
    headers: {
      contentType: "multipart/form-data",
    },
    responseType: "blob"
  });
};
