import { PayloadAction } from "@reduxjs/toolkit";
import { ProjectMessage } from "../models/project";
import { createSlice } from "../utils/@reduxjs/toolkit";
import { useInjectReducer } from "redux-injectors";
import { ProjectsState } from "./types";

export const initialProjectsState: ProjectsState = {
  projects: [],
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: "projects",
  initialState: initialProjectsState,
  reducers: {
    fetchProjectsLoading: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchProjectsSuccess: (state, action: PayloadAction<ProjectMessage[]>) => {
      state.isLoading = false;
      state.projects = action.payload;
    },
    fetchProjectsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    editProject: (state, action: PayloadAction<ProjectMessage>) => {
      const index = state.projects.findIndex((p) => p.id_project === action.payload.id_project);
      if (index !== -1) {
        state.projects[index] = action.payload;
      }
    },
    deleteProjects: (state, action: PayloadAction<number[]>) => {
      state.projects = state.projects.filter((project) => !action.payload.includes(project.id_project));
    },
  },
});

export const { actions } = slice;

export const useProjectsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
