import { PayloadAction } from '@reduxjs/toolkit';
import { DashboardProject } from '../models/project';
import { createSlice } from '../utils/@reduxjs/toolkit';
import { useInjectReducer } from 'redux-injectors';
import { DashboardState } from './types';

export const initialDashboardState: DashboardState = {
  projectList: [],
  isLoading: false,
};

const slice = createSlice({
  name: 'dashboard',
  initialState: initialDashboardState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    fetchCompletedProjects: (
      state,
      action: PayloadAction<DashboardProject[]>,
    ) => {
      state.projectList = action.payload;
    },
    editProject: (
      state, action: PayloadAction<DashboardProject>
    ) => {
      const index = state.projectList.findIndex((p) => p.id_project === action.payload.id_project);
      if (index !== -1) {
        state.projectList[index] = action.payload;
      }
    }
  },
});

export const { actions } = slice;

export const useDashboardSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
