import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Title, Legend } from 'chart.js';
import { COLOR_SCALES } from '../../../constants';

Chart.register([ArcElement, Tooltip, Title, Legend]);

function PieChart(props: any) {
  const { type, title, kpisChartInfo, chartUnits } = props;

  const getDataInfo = () => {
    const info = {
      labels: kpisChartInfo.map((value: any) => value[0]),
      datasets: [
        {
          label: 'Total',
          data: kpisChartInfo.map((value: any) => value[1]),
          backgroundColor: COLOR_SCALES[type],
          hoverOffset: 4,
        },
      ],
    };
    return info;
  };

  const options: any = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            const dataValue = tooltipItem.raw;
            return `${dataValue}${chartUnits ? chartUnits : ''}`;
          },
        },
      },
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          filter: function (legendItem: any, data: any) {
            const dataset = data.datasets[0];
            return dataset.data[legendItem.index] !== 0;
          },
          padding: 10,
          font: {
            size: 14,
            family: "'Arial', sans-serif",
          },
        },
      },
    },
  };

  return (
    <div className="chart-container">
      {' '}
      {/* Asegúrate de agregar esta clase */}
      {type && title && kpisChartInfo ? (
        <Pie data={getDataInfo()} options={options} />
      ) : null}
    </div>
  );
}

export default PieChart;
