import axios from 'axios';
import { Session } from '../models/login';
import { SERVER_API_DOTGIS } from '../config/env';
import { apiClient } from '../services/axiosConfig';
import { apiPaths } from '../services/config';

const SERVER = SERVER_API_DOTGIS;

interface Routes {
  GET_DATASET: string;
  RUN_DATASET: string;
}

export const ROUTES: Routes = {
  GET_DATASET: `dataset/list_user_datasets`,
  RUN_DATASET: `dataset/run_dataset`,
};

export const userLogin = async (
  email: string,
  password: string,
): Promise<Session> => {
  const res = await axios.post(`${SERVER}middleware/login/`, {
    username: email,
    password: password,
  });

  return res.data;
};

export const fetchData = async (data: string, endpoint: string) => {
  return await apiClient.get(`${ROUTES[endpoint]}/${data}`);
  // return await apiClient.get(`dataset/list_user_datasets/${userId}/${panel}`);
};

export const getXyzFolder = async (id_project: number, id_dataset: number) => {
  return await apiClient.get(
    `tile_service/get_tiles_dir/${id_project}/${id_dataset}`,
  );
};

export const getUserData = async () => {
  return await apiClient.get(`${apiPaths.user}/data`);
};

export const editUserData = async (unitsValue: number) => {
  const formData = new FormData();

  Object.entries(unitsValue).forEach(entry => {
    if (entry[1] !== null) formData.append(entry[0], entry[1]);
  });

  return await apiClient.patch(`${apiPaths.user}/data`, formData);
};
