import { Box, Slider, TextField, Typography } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import ButtonCustom from "../../../components/ui/button";
import useStyles from "../../../theme/styles";
import { Objective } from "../../../models/project";

interface Step4Props {
  activeStep: number;
  stepIndex: number;
  objective: Objective[];
  setObjective: (objective: Objective[]) => void;
  maxValueSliderMinArea: number;
  maxLabelValueSliderMinArea: number;
  showAreaSlider: boolean;
  showCloudCoverageSlider: boolean;
  showEndDateField: boolean;
  showStartDateField: boolean;
  showSpatialScaleSlider: boolean;
  isNextStepBlocked: boolean;
  handleStep: (step: number) => (event: React.MouseEvent<HTMLElement>) => void;
}

function Step4(props: Step4Props) {
  const {
    activeStep,
    stepIndex,
    objective,
    setObjective,
    maxValueSliderMinArea,
    maxLabelValueSliderMinArea,
    showAreaSlider,
    showCloudCoverageSlider,
    showEndDateField,
    showStartDateField,
    showSpatialScaleSlider,
    isNextStepBlocked,
    handleStep,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const renderDatePickerField = (params) => {
    const hasError = !params.inputProps.value;
    return <TextField {...params} error={hasError} helperText={hasError && "Please select a date"} />;
  };

  const handleChangeDateStart = (newValue) => {
    const newObjectives = objective.map((o: Objective) => {
      return { ...o, dateStart: newValue };
    });
    setObjective(newObjectives);
  };

  const handleChangeDateEnd = (newValue) => {
    const newObjectives = objective.map((o: Objective) => {
      return { ...o, dateEnd: newValue };
    });
    setObjective(newObjectives);
  };

  const handleChangeSelect = (e) => {
    const { name, value } = e.target;
    const newObjectives = objective.map((o: Objective) => {
      return { ...o, [name]: value };
    });
    setObjective(newObjectives);
  };

  const renderExtraInputs = (
    showCloudCoverageSlider: boolean,
    showAreaSlider: boolean,
    showStartDateField: boolean,
    showEndDateField: boolean,
    showSpatialScaleSlider: boolean,
  ) => {
    return (
      <>
        {(showStartDateField || showEndDateField) && (
          <>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>{t("common.date")}</Typography>
            <div className="containerStep2_date">
              {showStartDateField && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label={t("common.startDate")}
                    value={objective[0].dateStart}
                    onChange={handleChangeDateStart}
                    renderInput={(params) => renderDatePickerField(params)}
                    disabled={activeStep !== stepIndex}
                    disableFuture
                  />
                </LocalizationProvider>
              )}
              {showEndDateField && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label={t("common.endDate")}
                    value={objective[0].dateEnd}
                    onChange={handleChangeDateEnd}
                    renderInput={(params) => renderDatePickerField(params)}
                    disabled={activeStep !== stepIndex}
                    disableFuture
                  />
                </LocalizationProvider>
              )}
            </div>
          </>
        )}
        {showCloudCoverageSlider && (
          <>
            <label>{t("newProject.cloudCoverageField")}</label>
            <Box sx={{ width: 300 }} className="containerStep2_slider">
              <Slider
                size="small"
                valueLabelDisplay="auto"
                aria-label={t("newProject.cloudCoverageField")}
                value={objective[0].cloudCoverage}
                onChange={handleChangeSelect}
                name="cloudCoverage"
                min={0}
                max={100}
                marks={[
                  {
                    value: 0,
                    label: "0%",
                  },
                  {
                    value: 100,
                    label: "100%",
                  },
                ]}
              />
            </Box>
          </>
        )}
        {showAreaSlider && (
          <>
            <label>{t("newProject.areaField")}</label>
            <Box sx={{ width: 300 }} className="containerStep2_slider">
              <Slider
                size="small"
                valueLabelDisplay="auto"
                aria-label={t("newProject.areaField")}
                value={objective[0].minArea}
                onChange={handleChangeSelect}
                name="minArea"
                min={0}
                max={maxValueSliderMinArea}
                marks={[
                  {
                    value: 0,
                    label: "0 m²",
                  },
                  {
                    value: maxValueSliderMinArea,
                    label: `${maxLabelValueSliderMinArea} m²`,
                  },
                ]}
              />
            </Box>
          </>
        )}

        {showSpatialScaleSlider && (
          <>
            <label>{t("newProject.spatialScaleField")}</label>
            <Box sx={{ width: "90%" }} className="containerStep2_slider">
              <Slider
                size="small"
                valueLabelDisplay="auto"
                aria-label={t("newProject.spatialScaleField")}
                defaultValue={1}
                value={objective[0].spatialScale}
                onChange={handleChangeSelect}
                name="spatialScale"
                step={null}
                min={0.5}
                max={10}
                sx={{ "& .MuiSlider-markLabel": { fontSize: "small" } }}
                marks={[
                  {
                    value: 0.5,
                    label: "0.5m",
                  },
                  ...Array(10)
                    .fill(0)
                    .map((_, index) => {
                      return {
                        value: index + 1,
                        label: `${index + 1}m`,
                      };
                    }),
                ]}
              />
            </Box>
          </>
        )}
      </>
    );
  };

  return (
    <div className={activeStep === stepIndex ? classes.activeStep : classes.disabledStep}>
      {showCloudCoverageSlider || showStartDateField || showEndDateField || showAreaSlider || showSpatialScaleSlider ? (
        renderExtraInputs(
          showCloudCoverageSlider,
          showAreaSlider,
          showStartDateField,
          showEndDateField,
          showSpatialScaleSlider,
        )
      ) : (
        <Typography variant="body2">{t("newProject.noExtraParams")}</Typography>
      )}
      <Box sx={{ mb: 2 }}>
        <div className={`${stepIndex !== activeStep ? classes.hideBtn : ""}`}>
          <ButtonCustom
            label={t("common.backButton")}
            startIcon={<ArrowBackIcon />}
            className="backButton"
            handleClick={handleStep(2)}
            tooltip={t("common.backTooltip")}
          />
          <ButtonCustom
            label={t("common.continueButton")}
            className={isNextStepBlocked ? "buttonStyleColorDisable" : "buttonStyleColor"}
            variant="contained"
            handleClick={handleStep(4)}
            tooltip={t("common.continueTooltip", { extraText: "" })}
            disabled={isNextStepBlocked}
          />
        </div>
      </Box>
    </div>
  );
}

export default Step4;
