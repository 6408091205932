import { Typography } from "@mui/material";
import { arcGISLogin } from "../../services/arcgis";
import React from "react";

interface CustomArcGISAuthProps {
    icon?: React.ReactNode | undefined;
    text: string;
    textColor?: string | undefined;
    loginAvailable?: boolean;
}


const ArcGISAuth = (props: CustomArcGISAuthProps) => {
    const { icon, text, textColor, loginAvailable } = props
    const handleLogin = async () => {
        if (loginAvailable)
            await arcGISLogin().then((response) => {
                if (response.status == 200) {
                    const redirectUrl = response.data.redirectUrl;
                    window.location.href = redirectUrl;
                }
            }).catch((err) => {
                console.error(err)
            })
    };

    return (
        <>
            {icon !== undefined ? icon : null}
            <Typography
                align="center"
                onClick={handleLogin}
                sx={{
                    cursor: loginAvailable ? 'pointer' : 'default',
                    color: textColor !== undefined ? textColor : '#000',
                    textDecoration: 'underline'
                }}>
                {text}
            </Typography>
        </>
    );
};

export default ArcGISAuth;