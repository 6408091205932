import { useState } from "react";
import { Box, Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { downloadDataset } from "../../../services/datasets";
import fileDownload from "js-file-download";
import Loading from "../../loading";
import "./style.scss";
import { FILE_EXTENSION_TRANSLATIONS } from "../../../pages/projects/constants/fileExtensions";

function DownloadDataset(props: any) {
    const { t } = useTranslation()
    const {
        projectId,
        datasetsToDownload,
        buttonText,
        setSnackbarProps,
        setOpenSnackbar,
        fullWidth,
        variant,
        className,
    } = props

    const [anchorEl, setAnchorEl] = useState(null);
    const [openDonwload, setOpenDonwload] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const openMenu = (event: any) => {
        event?.stopPropagation();
        setOpenDonwload(!openDonwload);
        setAnchorEl(event?.currentTarget);
    };

    const handleDownloadDataset = async (event: any) => {
        event.stopPropagation();

        const typeExtension = event.currentTarget.dataset.value;

        setIsDownloading(true)
        openMenu(null);
        await downloadDataset(projectId, datasetsToDownload, typeExtension)
            .then(async function (response) {
                if (response.status === 200) {

                    const disposition = response.headers["content-disposition"];
                    let fileName = "downloaded-file"; // Default fallback filename
                    if (disposition && disposition.includes("filename")) {
                        fileName = disposition.split("filename=")[1].split(";")[0].replace(/"/g, "");
                    }
                    const fileBlob = new Blob([response.data], { type: response.headers["content-type"] });
                    fileDownload(fileBlob, fileName)
                }
            })
            .catch(function (err) {
                setSnackbarProps({ message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"), severity: 'error' })
                setOpenSnackbar(true)
            }).finally(() => {
                setIsDownloading(false)
            });
    };

    const renderFileOptions = () => {
        return Object.entries(FILE_EXTENSION_TRANSLATIONS).map(([fileType, fileExtension]) => {
            return (
                <Tooltip title={`${t('common.download')} ${fileExtension.text}`}>
                    <MenuItem
                        data-value={fileType}
                        key={fileType}
                        onClick={handleDownloadDataset}
                    >
                        {fileExtension.text}
                    </MenuItem>
                </Tooltip>)
        })
    }

    return (
        <>
            {
                !isDownloading ?
                    <Button
                        onClick={(e: any) => openMenu(e)}
                        fullWidth={fullWidth ? true : false}
                        variant={variant}
                        className={className}
                    >
                        {buttonText}
                        <Menu
                            anchorEl={anchorEl}
                            open={openDonwload}
                            onClose={(e: any) => openMenu(e)}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            disableScrollLock={true}
                            key={`${buttonText}_menu`}
                        >
                            {renderFileOptions()}
                        </Menu>
                    </Button> :
                    <Box sx={{ marginLeft: 1 }}>
                        <Loading size={35} />
                    </Box>
            }
        </>
    );
}

export default DownloadDataset;
