import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { getNormalizedTiff } from "../../services/files";

const TiffViewer = ({ file }) => {
  const [imageUrl, setImageUrl] = useState(null);

  const loadFromFile = async () => {
    await getNormalizedTiff(file).then((response) => {
      if (response.status === 200) {
        const fileBlob = new Blob([response.data], { type: response.headers["content-type"] });
        const url = URL.createObjectURL(fileBlob); // Crear URL temporal del blob
        setImageUrl(url);
      }
    }).catch((error) => {
      console.error("Error al cargar el archivo:", error);
    })
  };

  useEffect(() => {
    if (file && (file.type === "image/tiff" || file.type === "image/tif")) {
      loadFromFile();
    } else {
      console.log("Por favor, selecciona un archivo TIFF.");
    }

    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [file]);

  return (
    <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="TIFF Image"
          style={{
            display: "block",
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain"
          }}
        />
      ) : (
        <p>Cargando imagen...</p>
      )}
    </Box>
  );
};

export default TiffViewer;