import LayersIcon from '@mui/icons-material/Layers';
import { Button, Menu, Tooltip } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import useStyles from '../../theme/styles';
import './style.scss';
import { getBasemapOptions } from '../../services/areaOfInterest'
import { BASEMAP_OPTIONS } from '../../constants';

const DEFAULT_LAYER = 'mapbox_layer'


const BasemapWidget = props => {
  const classes = useStyles();
  const { layersArray, setLayersArray, rightCorner, areaOfInterest } = props;

  const [activeLayer, setActiveLayer] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const [availableLayers, setAvailableLayers] = useState(BASEMAP_OPTIONS);

  useEffect(() => {
    const filteredLayerArray = layersArray.filter(layer => {
      return layer.id.search('_layer') === -1;
    });
    if (availableLayers[activeLayer]?.layer)
      setLayersArray([availableLayers[activeLayer].layer, ...filteredLayerArray]);
    else setLayersArray(filteredLayerArray);
  }, [activeLayer]);

  useEffect(async () => {
    if (areaOfInterest?.features.length > 0) {
      const layerIds = Object.keys(BASEMAP_OPTIONS)
      await getBasemapOptions(areaOfInterest, layerIds).then(response => {
        if (response.status === 200) {
          const newLayerIds = response.data.message;
          const filteredBasemapOptions = newLayerIds.reduce((acc, key) => {
            if (key in BASEMAP_OPTIONS) {
              acc[key] = BASEMAP_OPTIONS[key]
            }
            return acc
          }, {});
          setAvailableLayers(filteredBasemapOptions)
          const newLayersArray = layersArray.filter(layer=>{
            return newLayerIds.includes(layer.id)
          })
          setLayersArray(newLayersArray)
          if (!newLayerIds.includes(activeLayer))
            setActiveLayer(DEFAULT_LAYER)
        }
      }).catch(e => {
        console.log(e)
      })
    } else {
      setAvailableLayers(BASEMAP_OPTIONS)
    }
  }, [areaOfInterest])

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLayer = event => {
    setActiveLayer(event.target.value);
  };

  return (
    <div className={classes.flexRow}>
      <Tooltip title="Basemap">
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className={`widget-btn ${classes.btn} ${open ? classes.btnOpen : classes.btnNoOpen}`}
          variant="contained"
          startIcon={<LayersIcon className={classes.btn_search} />}
        />
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        style={{ marginTop: '0.5px' }}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: rightCorner ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: rightCorner ? 'right' : 'left',
        }}
      >
        <FormControl sx={{ paddingLeft: '10px' }}>
          <RadioGroup
            value={activeLayer || DEFAULT_LAYER}
            onChange={handleChangeLayer}
          >
            {
              Object.entries(availableLayers).map(([layerId, layerValues]) => {
                return (
                  <FormControlLabel
                    value={layerId}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: '#7024D1',
                          '&.Mui-checked': { color: '#7024D1' },
                        }}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: 'small' }}>
                        {layerValues.layerName}
                      </Typography>
                    }
                  />
                )
              })
            }
          </RadioGroup>
        </FormControl>
      </Menu>
    </div>
  );
};

BasemapWidget.propTypes = {
  mapObject: PropTypes.any,
  changeViewport: PropTypes.func,
};

export default BasemapWidget;
