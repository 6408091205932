import { FileCopyOutlined } from "@mui/icons-material";
import { Box, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import PublicIcon from '@mui/icons-material/Public';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { useState } from "react";
import DownloadDataset from "../downloadDataset";
import { useTranslation } from "react-i18next";
import SharingCard from "./sharingCard";
import { publishArcGIS } from "../../../services/arcgis";


function SharingTab(props: any) {

    const { t } = useTranslation();
    const {
        project,
        datasets,
        setSnackbarProps,
        setOpenSnackbar,
    } = props;

    const token = localStorage.getItem("token");

    const [selectedDatasets, setSelectedDatasets] = useState([]);

    const handleCheckboxChange = (id_dataset: number) => {
        setSelectedDatasets((prevSelected) =>
            prevSelected.includes(id_dataset)
                ? prevSelected.filter((cardId) => cardId !== id_dataset)
                : [...prevSelected, id_dataset]
        );
    };
    const isSelected = (id: number) => selectedDatasets.includes(id);

    const handleCopyPassword = () => {
        if (token) {
            navigator.clipboard.writeText(token);
        }
    };

    const handleCopyDatasetUrl = (dataset: any) => {
        setSnackbarProps({ message: t("common.comingSoon"), severity: 'info' })
        setOpenSnackbar(true)
    }

    const handleExportToPDF = async () => {
        setSnackbarProps({ message: t("common.comingSoon"), severity: 'info' })
        setOpenSnackbar(true)
    }

    const handlePublishArcGIS = async () => {
        const projectId = datasets[0].id_project

        await publishArcGIS(projectId, selectedDatasets).then(
            (response) => {
                if (response.status === 200) {
                    setSnackbarProps({ message: t("common.genericSuccessMesage"), severity: 'success' })
                    setOpenSnackbar(true)
                }
            }
        ).catch(
            (error) => {
                setSnackbarProps({ message: error.response?.data ? error.response.data.message : t("common.genericErrorMessage"), severity: 'error' })
                setOpenSnackbar(true)
            }
        )
    }

    return (
        <Stack spacing={5}>
            <TextField
                label="Token"
                disabled
                value={token}
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={handleCopyPassword}>
                            <FileCopyOutlined />
                        </IconButton>
                    ),
                }}
                sx={{
                    "& .Mui-disabled": {
                        color: "black",
                        "-webkit-text-fill-color": "black",
                    },
                }}
            />
            <Stack spacing={2}>
                {
                    datasets.map((dataset: any) => {
                        return <SharingCard
                            dataset={dataset}
                            isSelected={isSelected}
                            handleCheckboxChange={handleCheckboxChange}
                            handleCopyDatasetUrl={handleCopyDatasetUrl}
                        />
                    })
                }
            </Stack>
            <Box>
                <Typography sx={{ fontWeight: 'bold' }}>{t('sharing.downloadDatasets')}</Typography>
                <Grid container sx={{ marginTop: 2 }}>
                    <Grid item xs={6}>
                        <Box sx={{ marginLeft: 2, marginRight: 2 }}>
                            <DownloadDataset
                                fullWidth
                                variant="contained"
                                className={"buttonImageryst buttonStyleColoOutline"}
                                projectId={datasets[0].id_project}
                                datasetsToDownload={selectedDatasets}
                                buttonText={t('sharing.selectedDatasets')}
                                setSnackbarProps={setSnackbarProps}
                                setOpenSnackbar={setOpenSnackbar}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={6}>
                        <Box sx={{ marginLeft: 2, marginRight: 2 }}>
                            <DownloadDataset
                                fullWidth
                                variant="contained"
                                className={`buttonImageryst buttonStyleColor`}
                                projectId={datasets[0].id_project}
                                datasetsToDownload={datasets.map((dataset: any) => dataset.id_dataset)}
                                buttonText={t('sharing.allDatasets')}
                                setSnackbarProps={setSnackbarProps}
                                setOpenSnackbar={setOpenSnackbar}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Stack spacing={1.5}>
                <Typography sx={{ fontWeight: 'bold' }}>{t('sharing.otherActions')}</Typography>
                <Box display="flex" alignItems="center">
                    <PictureAsPdfIcon sx={{ mr: 1 }} />
                    <Typography onClick={handleExportToPDF} sx={{ cursor: 'pointer', color: '#000', textDecoration: 'underline' }}>
                        {t('sharing.exportPDF')}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                    <PublicIcon sx={{ mr: 1 }} />
                    <Typography onClick={handlePublishArcGIS} sx={{ cursor: 'pointer', color: '#000', textDecoration: 'underline' }}>
                        {t('sharing.publishArcGIS')}
                    </Typography>
                </Box>
            </Stack>

        </Stack>
    )

}

export default SharingTab;