import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Tab,
  Tabs,
  Menu,
  MenuItem,
  Button,
  Tooltip,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ButtonCustom from '../../components/ui/button';
// import LanguageSelector from "../languageSelector";
import { Imageryst7 } from '../../icons/logo';
import { localStorageKeys } from '../../services/config';
import { navigation } from '../../utils/navigation';
import './style.scss';

function Header() {
  const history = useHistory();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const currentPath: string = pathname.split('/')[1];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const validPaths = new Set(['projects', 'dashboardResults', 'images']);
  const path: string | boolean = validPaths.has(currentPath)
    ? currentPath
    : false;

  const handleLogout = () => {
    localStorage.removeItem(localStorageKeys.ACCESS_TOKEN);
    localStorage.removeItem('id_user');

    history.push(navigation.login);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="menu_container">
      <div className="logo">
        <Grid
          container
          spacing={5}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <Grid item>
            <Imageryst7 sx={{ width: '55.13px', height: '51.75px' }} />
          </Grid>
          {/* <Grid item>
            <LanguageSelector />
          </Grid> */}
        </Grid>
      </div>
      <div className="menu">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="tabs">
          <Tabs
            className="menu"
            value={path}
            textColor="secondary"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#7024d1',
              },
            }}
          >
            <Tab
              value="projects"
              label={t('common.projects')}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                history.push(navigation.projects);
              }}
            />
            <Tab
              value="dashboardResults"
              label={t('common.dashboardResults')}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                history.push(navigation.dashboardResults);
              }}
            />
            <Tab
              value="images"
              label={t('common.images')}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                history.push(navigation.images);
              }}
            />
          </Tabs>
        </Box>
      </div>
      <div className="user">
        <div className="container_newP">
          {path === 'projects' ? (
            <ButtonCustom
              label={t('newProject.newProject')}
              className="buttonStyleColor"
              variant="contained"
              handleClick={() => history.push(navigation.newProject)}
              tooltip="Create new project"
            />
          ) : null}
        </div>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleMenuClick}
        >
          <Imageryst7 sx={{ width: 'inherit', height: 'inherit' }} />
          <ExpandMoreIcon style={{ color: '#7024D1' }} />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          disableScrollLock={true}
        >
          <Tooltip title={t('common.profile')}>
            <MenuItem onClick={() => history.push(navigation.profile)}>
              {t('common.profile')}
            </MenuItem>
          </Tooltip>
          <Tooltip title={t('common.logout')}>
            <MenuItem onClick={handleLogout}>{t('common.logout')}</MenuItem>
          </Tooltip>
        </Menu>
      </div>
    </div>
  );
}

export default Header;
