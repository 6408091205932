import React, { useEffect, useState, useRef, useCallback } from 'react';
import DeckGL from '@deck.gl/react';
import { StaticMap, NavigationControl, MapContext } from 'react-map-gl';
import { WebMercatorViewport, FlyToInterpolator } from '@deck.gl/core';
import { IconLayer } from '@deck.gl/layers';
import {
  EditableGeoJsonLayer,
  DrawPolygonMode,
  DrawLineStringMode,
  ViewMode,
} from '@deck.gl-community/editable-layers';
import { useDispatch, useSelector } from 'react-redux';
import { area, length } from '@turf/turf';
import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GeoJsonLayer } from '@deck.gl/layers';
import SearchMap from '../../components/searchMap';
import BasemapWidget from '../../components/basemapWidget';
import MeasurementMap from '../../components/measurementMap';
import useStyles from '../../theme/styles';
import logo_event from '../../img/eventoutlined-6@2x.png';
import logo_event_hover from '../../img/eventoutlined-5@2x.png';
import locationMark from '../../img/position.png';
import { MAPBOX_ACCESS_TOKEN } from '../../config/env';
import { useAppSlice } from '../../slice';
import { openMapPanelSelector } from '../../slice/selectors';
import { ICON_MAPPING, INITIAL_VIEW_STATE } from '../../constants/mapOptions';
import './style.scss';

const MAPBOX_STYLE = 'mapbox://styles/mapbox/satellite-streets-v11';

function Map(props) {
  const { layersArray, initialMapBbox, setLayersArray, aoi } = props;
  const classes = useStyles();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useAppSlice();

  const openMapPanel = useSelector(openMapPanelSelector);

  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
  const [viewStateBBOX, setViewStateBBOX] = useState('');
  const [icon, setIcon] = useState(logo_event);

  const [aoiVisible, setAoiVisible] = useState(false);

  const [featuresResultSearch, setFeaturesResultSearch] = useState([]);

  const deckRef = useRef(null);

  const onViewStateChange = useCallback(({ viewState: newViewState }) => {
    setViewState(newViewState);
  }, []);

  useEffect(() => {
    if (aoi && aoiVisible) {
      const aoiLayer = createAOILayer(aoi);
      setLayersArray(oldArray => [...oldArray, aoiLayer]);
    } else {
      const layerNoSelect = layersArray.filter(
        layer => layer.id !== 'AOILayer',
      );
      setLayersArray(layerNoSelect);
    }
  }, [aoi, aoiVisible]);

  const createAOILayer = aoi => {
    return new GeoJsonLayer({
      id: 'AOILayer',
      data: aoi,

      stroked: true,
      filled: true,
      pickable: false,

      getFillColor: [112, 39, 209, 0],
      getLineColor: [112, 39, 209],
      getText: f => f.properties.name,
      getLineWidth: 3,
      lineWidthUnits: 'pixels',
    });
  };

  const [controllerMap, setControllerMap] = useState({ doubleClickZoom: true });

  useEffect(() => {
    if (viewStateBBOX !== '' && viewStateBBOX !== undefined) {
      if (viewStateBBOX.length === 0) {
        setFeaturesResultSearch([
          {
            coordinates: [0, 0],
          },
        ]);
      } else {
        setViewState({
          longitude: viewStateBBOX[0],
          latitude: viewStateBBOX[1],
          zoom: 17,
          pitch: 0,
          bearing: 0,
          minZoom: 11,
          transitionDuration: 2000,
          transitionInterpolator: new FlyToInterpolator(),
        });

        setFeaturesResultSearch([
          {
            coordinates: [viewStateBBOX[0], viewStateBBOX[1]],
          },
        ]);
      }
    }
  }, [viewStateBBOX]);

  const layerResultSearch = new IconLayer({
    id: 'icon-layer-result-search',
    data: featuresResultSearch,
    pickable: true,
    iconAtlas: locationMark,
    iconMapping: ICON_MAPPING,
    getIcon: () => 'marker',
    sizeScale: 6,
    getPosition: d => {
      return d.coordinates;
    },
    getSize: () => 6,
    getColor: [112, 36, 209],
  });

  useEffect(() => {
    if (initialMapBbox.bbox) {
      setViewState(prevViewState => {
        const newViewState = new WebMercatorViewport(prevViewState);
        try {
          const { longitude, latitude, zoom } = newViewState.fitBounds(
            [
              [initialMapBbox.bbox[0], initialMapBbox.bbox[1]],
              [initialMapBbox.bbox[2], initialMapBbox.bbox[3]],
            ],
            { padding: 20 },
          );

          return {
            longitude,
            latitude,
            zoom: initialMapBbox.zoom ? initialMapBbox.zoom : zoom,
            pitch: 0,
            bearing: 0,
            minZoom: 11,
            transitionDuration: 2000,
            transitionInterpolator: new FlyToInterpolator(),
          };
        } catch (e) {
          console.log(e);
        }
      });
    }
  }, [initialMapBbox]);

  const formatValue = (value, decimals = 0) => {
    const v = formatNumber(value, decimals).toString();
    const formated = v.replace('.', ',');
    return formated;
  };

  const formatNumber = (value, decimals = 2) => {
    if (value === 0) {
      return value;
    } else if (value === 1) {
      return value;
    } else if (value === -1) {
      return value;
    } else {
      return value.toFixed(decimals);
    }
  };

  const [mode, setMode] = useState(() => ViewMode);
  const [selectedFeIndex, setSelectedFeIndex] = useState([0]);
  const [features, setFeatures] = useState({
    type: 'FeatureCollection',
    features: [],
  });

  const [areaMeasurement, setAreaMeasurement] = useState(null);
  const [lengthMeasurement, setLengthMeasurement] = useState(null);
  const [panelLengthMeasurement, setPanelLengthMeasurement] = useState(null);
  const [panelAreaMeasurement, setPanelAreaMeasurement] = useState(null);

  const layerEdit = new EditableGeoJsonLayer({
    data: features,
    mode,
    pickable: true,
    selectedFeatureIndexes: selectedFeIndex,
    autoHighlight: true,
    onEdit: ({ updatedData, editType }) => {
      if (editType === 'addFeature') {
        let arraySelectedFeature = [];
        updatedData.features.forEach((f, index) => {
          arraySelectedFeature.push(index);
        });
        setSelectedFeIndex(arraySelectedFeature);
      }
      setFeatures(updatedData);
    },
    onClick: () => {
      //setSelectedFeIndex((prevState)=>[...prevState, info.index]);
      //setMode(ModifyMode);
    },
    getFillColor: () => [112, 39, 209, 50],
    getLineColor: () => [112, 39, 209],
    highlightColor: () => [112, 39, 209, 50],
    getEditHandlePointColor: () => [112, 39, 209],
    getEditHandlePointOutlineColor: () => [175, 155, 201],
  });

  const activateToolArea = () => {
    //e.stopPropagation();
    if (panelAreaMeasurement) {
      setControllerMap({ doubleClickZoom: true });
      setMode(() => ViewMode);
      setFeatures({
        type: 'FeatureCollection',
        features: [],
      });
    } else {
      setControllerMap({ doubleClickZoom: false });
      setAreaMeasurement(null);
      setLengthMeasurement(null);
      setPanelLengthMeasurement(false);
      setMode(() => DrawPolygonMode);
      setFeatures({
        type: 'FeatureCollection',
        features: [],
      });
    }
  };

  const activateToolLength = () => {
    //e.stopPropagation();
    if (panelLengthMeasurement) {
      setControllerMap({ doubleClickZoom: true });
      setMode(() => ViewMode);
      setFeatures({
        type: 'FeatureCollection',
        features: [],
      });
    } else {
      setControllerMap({ doubleClickZoom: false });
      setAreaMeasurement(null);
      setLengthMeasurement(null);
      setPanelAreaMeasurement(false);
      setMode(() => DrawLineStringMode);
      setFeatures({
        type: 'FeatureCollection',
        features: [],
      });
    }
  };

  useEffect(() => {
    if (features.features.length > 0) {
      const ar = area(features);
      setAreaMeasurement(`${formatValue(ar)} m²`);

      const len = length(features);
      setLengthMeasurement(`${formatValue(len * 1000)} m`);
    }
  }, [features]);

  return (
    <div className="container_dashboardMap">
      <DeckGL
        viewState={viewState}
        controller={controllerMap}
        layers={layersArray.concat(layerResultSearch, layerEdit)}
        getTooltip={({ object }) => {
          let htmlContent = `<div class="custom-tooltip">`;
          if (!object || !object.properties) {
            return null;
          }
          if (object.properties.luminosity !== undefined) {
            htmlContent =
              htmlContent +
              `
              <b>Luminosity:</b> ${formatValue(object.properties.luminosity)} <br>
              <b>Image date: </b> ${object.properties.image_date} <br>
              <b>Area (m²):</b> ${formatValue(object.properties.area)}
            `;
          } else if (object.properties.category !== undefined) {
            htmlContent =
              htmlContent +
              `
              <b>Category:</b> ${object.properties.category} <br>
            `;
          } else if (
            object.properties.altitude &&
            object.properties.slope &&
            object.properties.aspect
          ) {
            if (object.properties.area) {
              htmlContent =
                htmlContent +
                `
                  <b>Altitude:</b> ${formatValue(object.properties.altitude, 1)} <br>
                  <b>Aspect:</b> ${formatValue(object.properties.aspect)} <br>
                  <b>Slope:</b> ${formatValue(object.properties.slope)}<br>
                  <b>Area (m²):</b> ${formatValue(object.properties.area)}`;
            } else {
              htmlContent =
                htmlContent +
                `
                  <b>Altitude:</b> ${formatValue(object.properties.altitude, 1)} <br>
                  <b>Aspect:</b> ${formatValue(object.properties.aspect)} <br>
                  <b>Slope:</b> ${formatValue(object.properties.slope)}`;
            }
          } else if (object && object.properties.aspect) {
            htmlContent = `${htmlContent}
                <b>${formatValue(object.properties.aspect)}º (${object.properties.orientation})</b>`;
          } else if (object && object.properties.radiation) {
            return `${formatValue(object.properties.radiation)}`;
          } else if (object && object.properties.slope) {
            htmlContent = `${htmlContent}
                <b>Slope:</b> ${formatValue(object.properties.slope)}º
                <br>
                <b>Slope percentage:</b> ${object.properties.slope_percent}%`;
          } else if (object && object.properties.altitude) {
            return `${formatValue(object.properties.altitude, 1)}`;
          } else if (object && object.properties.humidity) {
            return `${formatValue(object.properties.humidity)}`;
          } else if (object && object.properties.water_bodies) {
            return `${formatValue(object.properties.water_bodies)}`;
          } else if (
            object.properties.ndvi >= -1 &&
            object.properties.ndvi <= 1 &&
            object.properties.area
          ) {
            htmlContent =
              htmlContent +
              `
                <b>Area (m²):</b> ${formatValue(object.properties.area)} <br>
                <b>Status: </b> ${object.properties.status} <br>
                <b>Image date: </b> ${object.properties.image_date}`;
          } else if (
            object.properties.ndwi >= -1 &&
            object.properties.ndwi <= 1 &&
            object.properties.area
          ) {
            htmlContent =
              htmlContent +
              `
                <b>Area (m²):</b> ${formatValue(object.properties.area)} <br>
                <b>Status: </b> ${object.properties.status} <br>
                <b>Image date: </b> ${object.properties.image_date}`;
          } else if (object.properties.area && object.properties.n_panels) {
            htmlContent =
              htmlContent +
              `
                <b>Area (m²):</b> ${formatValue(object.properties.area)} <br>
                <b>Number of panels:</b> ${formatValue(object.properties.n_panels)}`;
          } else if (object.properties.height) {
            return `${formatValue(object.properties.height)}`;
          } else if (object && object.properties.footprint_area) {
            return {
              html: `<b>Area (m²):</b> ${formatValue(object.properties.footprint_area)}`,
            };
          } else if (
            object &&
            object.properties.area &&
            object.properties.image_date
          ) {
            return {
              html: `<b>Area (m²):</b> ${formatValue(object.properties.area)}<br>Image date: ${object.properties.image_date
                }`,
            };
          } else if (object && object.properties.area) {
            return {
              html: `<b>Area (m²):</b> ${formatValue(object.properties.area)}`,
            };
          } else if (object && object.properties.confidence) {
            return {
              html: `<b>Confidence:</b> ${formatValue(parseFloat(object.properties.confidence), 2)}`,
            };
          } else if (
            object &&
            object.properties.vegetation_height &&
            object.properties.form_tree &&
            object.properties.vegetation_type_1 &&
            object.properties.vegetation_type_2 &&
            object.properties.vegetation_type_3
          ) {
            return {
              html: `<b>Form tree:</b> ${object.properties.form_tree} <br>
              <b>Vegetation type 1: </b> ${object.properties.vegetation_type_1} <br>
              <b>Vegetation type 2: </b> ${object.properties.vegetation_type_2} <br>
              <b>Vegetation type 3: </b> ${object.properties.vegetation_type_3} <br>
              <b>Altitude: </b> ${formatNumber(object.properties.vegetation_height)}`,
            };
          } else if (object && object.properties?.layerName) {
            if (object.properties?.layerName.includes('rooftop_type')) {
              htmlContent =
                htmlContent +
                `
                  <b>Median aspect:</b> ${formatValue(object.properties.median_aspect)}º <br>
                  <b>Median slope:</b> ${formatValue(object.properties.median_slope)} <br>
                  <b>Roof type:</b> ${object.properties.roof_type} <br>
                  <b>Roof type o:</b> ${object.properties.roof_type_o} <br>
                  ${object.properties.reference ? '<b>Reference:</b> ' + object.properties.reference : ''}`;
            } else if (
              object.properties?.layerName.includes('footprints_results')
            ) {
              htmlContent =
                htmlContent +
                `
                  <b>Area (m²):</b> ${formatValue(object.properties.footprint_area)}<br>
                  ${object.properties.reference ? '<b>Reference:</b> ' + object.properties.reference : ''}`;
            }
          }
          return object && { html: htmlContent + '</div>' };
        }}
        onViewStateChange={onViewStateChange}
        ref={deckRef}
        getCursor={() => 'default'}
        ContextProvider={MapContext.Provider}
      >
        <div className="container-btn-map-zoom">
          <NavigationControl position="bottom-left" showCompass={false} />
        </div>
        <StaticMap
          mapStyle={MAPBOX_STYLE}
          mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
        />

        <div className="widget-container">
          <Tooltip title={t('newProject.step2Title')}>
            <Button
              className={`widget-btn ${classes.btn}`}
              sx={{ color: aoiVisible ? '#7024D1' : 'black' }}
              variant="text"
              onClick={() => setAoiVisible(!aoiVisible)}
              disabled={!aoi}
            >
              AOI
            </Button>
          </Tooltip>
          <BasemapWidget
            layersArray={layersArray}
            setLayersArray={setLayersArray}
            areaOfInterest={aoi}
          />
          <MeasurementMap
            activeTool={activateToolArea}
            type="area"
            measurement={areaMeasurement}
            statePanel={panelAreaMeasurement}
            setStatePanel={setPanelAreaMeasurement}
          />
          <MeasurementMap
            activeTool={activateToolLength}
            type="length"
            measurement={lengthMeasurement}
            statePanel={panelLengthMeasurement}
            setStatePanel={setPanelLengthMeasurement}
          />
          <SearchMap changeViewport={setViewStateBBOX} />
          <Tooltip title={t('dashboardResults.projectInfo')}>
            <Button
              className={`widget-btn ${classes.btn}`}
              variant="contained"
              onClick={() => dispatch(actions.setOpenMapPanel(!openMapPanel))}
              startIcon={
                <img
                  src={icon}
                  onMouseEnter={() =>
                    !openMapPanel && setIcon(logo_event_hover)
                  }
                  onMouseLeave={() => !openMapPanel && setIcon(logo_event)}
                />
              }
            />
          </Tooltip>
        </div>
      </DeckGL>
    </div>
  );
}

export default Map;
