import { scaleLinear, scaleThreshold } from "d3-scale";

export const COLOR_SCALE_ASPECT = scaleThreshold<number, number[]>()
  .domain([45, 135, 225, 315, 360])
  .range([
    [141, 90, 153],
    [250, 232, 35],
    [251, 22, 12],
    [60, 234, 17],
    [141, 90, 153],
  ]);

export const COLOR_SCALE_SLOPE = scaleThreshold<number, number[]>()
  .domain([5, 15, 25, 50, 86])
  .range([
    [26, 150, 65],
    [166, 217, 106],
    [230, 255, 192],
    [253, 174, 97],
    [215, 25, 28],
  ]);

export const COLOR_SCALE_NDVI = scaleThreshold<number, number[]>()
  .domain([0.3, 0.5, 0.6, 0.7, 0.8, 1])
  .range([
    [251, 22, 12],
    [239, 69, 17],
    [239, 171, 25],
    [26, 150, 65],
    [21, 225, 72],
    [124, 237, 96],
  ]);

export const COLOR_SCALE_NIGHT_TIME_LIGHTS = scaleThreshold<number, number[]>()
  .domain([5, 10, 20, 30, 40, 50, 70, 75])
  .range([
    [0, 0, 0],
    [36, 31, 0],
    [73, 62, 0],
    [109, 93, 0],
    [146, 124, 0],
    [182, 155, 0],
    [219, 186, 0],
    [255, 213, 0],
  ]);

export const COLOR_SCALE_VEGETATION_HEALTH = scaleLinear<number[]>()
  .domain([-1, -0.01, 0.17, 0.33, 0.49, 0.66, 0.83, 1])
  .range([
    [215, 25, 28],
    [240, 124, 74],
    [254, 201, 129],
    [196, 230, 135],
    [119, 195, 92],
    [91, 174, 61],
    [26, 150, 65],
  ]);

export const COLOR_SCALE_HUMIDITY = scaleThreshold<number, number[]>()
  .domain([-0.5, -0.2, 0, 0.15, 0.3, 0.5, 1])
  .range([
    [35, 145, 36],
    [154, 204, 151],
    [210, 252, 205],
    [213, 242, 255],
    [32, 144, 251],
    [11, 51, 236],
    [5, 25, 135],
  ]);

export const COLOR_SCALE_WATER_BODIES = scaleThreshold<number, number[]>()
  .domain([0.3, 0.5, 1.0])
  .range([
    [42, 185, 238],
    [66, 73, 177],
    [48, 18, 59],
  ]);

export const COLOR_SCALE_EARTHWORK_MONITORING = scaleThreshold<number, number[]>()
  .domain([1, 2])
  .range([
    [30, 115, 2],
    [115, 59, 2],
    [100, 100, 100],
  ]);

export const COLOR_RADIATION = [
  "rgb(59, 27, 0)",
  "rgb(93, 42, 0)",
  "rgb(171, 86, 3)",
  "rgb(198, 105, 5)",
  "rgb(237, 165, 33)",
  "rgb(250, 198, 53)",
  "rgb(255, 230, 70)",
];

export const COLOR_ASPECT = [
  "rgb(141, 90, 153)",
  "rgb(250, 232, 35)",
  "rgb(251, 22, 12)",
  "rgb(60, 234, 17)",
  "rgb(141, 90, 153)",
];

export const COLOR_SLOPE = [
  "rgb(26, 150, 65)",
  "rgb(166, 217, 106)",
  "rgb(230, 255, 192)",
  "rgb(253, 174, 97)",
  "rgb(215, 25, 28)",
];

export const COLOR_NDVI = [
  "rgb(251, 22, 12)",
  "rgb(239, 69, 17)",
  "rgb(239, 171, 25)",
  "rgb(26, 150, 65)",
  "rgb(21, 225, 72)",
  "rgb(124, 237, 96)",
];

export const COLOR_ALTITUDE = [
  "rgb(0, 102, 51)",
  "rgb(0, 153, 51)",
  "rgb(0, 204, 102)",
  "rgb(153, 255, 102)",
  "rgb(255, 255, 102)",
  "rgb(255, 204, 102)",
  "rgb(255, 153, 51)",
  "rgb(204, 102, 0)",
  "rgb(153, 102, 51)",
  "rgb(102, 51, 0)",
];

export const COLOR_VEGETATION_HEALTH = [
  "rgb(215, 25, 28)",
  "rgb(240, 124, 74)",
  "rgb(254, 201, 129)",
  "rgb(196, 230, 135)",
  "rgb(119, 195, 92)",
  "rgb(91, 174, 61)",
  "rgb(26, 150, 65)",
];

export const COLOR_HUMIDITY = [
  "rgb(35,145,36)",
  "rgb(154,204,151)",
  "rgb(210, 252, 205)",
  "rgb(213, 242, 255)",
  "rgb(32, 144, 251)",
  "rgb(11, 51, 236)",
  "rgb(5, 25, 135)",
];

export const COLOR_WATER_BODIES = ["rgb(42, 185, 238)", "rgb(66, 73, 177)", "rgb(48, 18, 59)"];

export const COLOR_NIGHT_TIME_LIGHTS = [
  "rgb(0, 0, 0)",
  "rgb(36, 31, 0)",
  "rgb(73, 62, 0)",
  "rgb(109, 93, 0)",
  "rgb(146, 124, 0)",
  "rgb(182, 155, 0)",
  "rgb(219, 186, 0)",
  "rgb(255, 213, 0)",
];

export const COLOR_EARTHWORK_MONITORING = [
  "rgb(178, 223, 138)",
  "rgb(27, 160, 0)",
  "rgb(150, 150, 150)",
  "rgb(39, 191, 229)",
  "rgb(255, 235, 133)",
];

export const COLOR_SCALES = {
  Altitude: COLOR_ALTITUDE,
  Radiation: COLOR_RADIATION,
  Aspect: COLOR_ASPECT,
  Slope: COLOR_SLOPE,
  "Slope (Pitch)": COLOR_SLOPE,
  NDVI: COLOR_NDVI,
  "Vegetation health": COLOR_VEGETATION_HEALTH,
  Humidity: COLOR_HUMIDITY,
  "Water bodies": COLOR_WATER_BODIES,
  "Night time lights": COLOR_NIGHT_TIME_LIGHTS,
  Height: COLOR_ALTITUDE,
  "Vegetation height": COLOR_ALTITUDE,
  "Earthworks Monitoring": COLOR_EARTHWORK_MONITORING,
};
