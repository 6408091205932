import { Aoi, InputImages } from '../models/project';
import { apiClient } from '../services/axiosConfig';
import { apiPaths } from '../services/config';

export const getAoiBuffer = async (aoi: Aoi, bufferSize: string) => {
  const formData = new FormData();

  formData.append('area_of_interest', JSON.stringify(aoi));
  formData.append('size', bufferSize);

  return await apiClient.post(`${apiPaths.aoi}/create_buffer`, formData);
};

export const checkAoi = async (aoi: Aoi, inputImages: InputImages[]) => {
  const formData = new FormData();

  formData.append('area_of_interest', JSON.stringify(aoi));
  formData.append('input_images', JSON.stringify(inputImages));

  return await apiClient.post(`${apiPaths.aoi}/check_aoi`, formData);
};

export const getBasemapOptions = async (aoi: Aoi, layerIds: string[]) => {
  const formData = new FormData();

  formData.append('area_of_interest', JSON.stringify(aoi));
  formData.append('layer_ids', JSON.stringify(layerIds));

  return await apiClient.post(`${apiPaths.aoi}/available_layers`, formData);
};
