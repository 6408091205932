/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Tabs,
  Tab,
  Typography,
  Stack,
  TextField,
} from '@mui/material';
import CustomSnackbar, { SnackbarProps } from '../../../components/snackbar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import TableC from '../../../components/table';
import ButtonCustom from '../../../components/ui/button';
import { Dataset, ProjectMessage } from '../../../models/project';
import { formatAreaToKm2 } from '../../../utils/utils';
import { EditProjectModal, ModalCustom } from '../../../components/modal';
import { addComment } from '../../../services/projects';
import {
  deleteDataset,
  downloadAoi,
  runDataset,
} from '../../../services/datasets';
import fileDownload from 'js-file-download';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../../slice/selectors';
import { FILE_EXTENSION_TRANSLATIONS } from '../constants/fileExtensions';
import { useProjectsSlice } from '../../../slice/projects';
import { ATTRIBUTE_ZOOM } from '../../../constants/mapOptions';

interface ProjectCardProps {
  project: ProjectMessage;
  selectedProjects: number[];
  handleSelectedProjects: (projectId: number) => void;
}

export const ProjectCard: React.FC<ProjectCardProps> = ({
  project,
  selectedProjects,
  handleSelectedProjects,
}) => {
  const { t } = useTranslation();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const { actions: projectActions } = useProjectsSlice();

  const [tabValue, setTabValue] = useState('stats');
  const [openModalEditProject, setOpenModalEditProject] = useState(false);
  const [openModalAddComment, setOpenModalAddComment] = useState(false);
  const [openDeleteDatasetModal, setOpenDeleteDatasetModal] = useState(false);
  const [openExecuteDatasetModal, setOpenExecuteDatasetModal] = useState(false);
  const [selectedDatasets, setSelectedDatasets] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [downloadActive, setDownloadActive] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>({
    message: '',
    severity: 'info',
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleAddComment = async () => {
    const editedProject = await addComment(project.id_project, newComment)
      .then(r => {
        if (r.status === 200) {
          return r.data.project;
        }
        return null;
      })
      .catch(() => {
        return null;
      });
    if (editedProject) {
      dispatch(projectActions.editProject(editedProject));
      setOpenModalAddComment(false);
      setNewComment('');
    }
  };

  const handleDownloadAoiClick = async (
    project: ProjectMessage,
    fileFormat: string,
  ) => {
    setDownloadActive(true);
    await downloadAoi(user.id_user, project.id_project, fileFormat)
      .then(function (response) {
        if (response.status === 200) {
          fileDownload(
            response.data,
            `${project.name_project}${FILE_EXTENSION_TRANSLATIONS[fileFormat].extension}`,
          );
        }
      })
      .catch(function () {});
    setDownloadActive(false);
  };

  const datasetsWithParameters = () => {
    const hasParameters = project.datasets.reduce(
      (acc: boolean, dataset: Dataset) => {
        return (
          acc ||
          !!dataset.input_alg_parameters?.start_date ||
          !!dataset.input_alg_parameters?.end_date ||
          !!dataset.input_alg_parameters?.cloud_percentage ||
          !!dataset.input_alg_parameters?.spatial_scale ||
          !!dataset.input_alg_parameters?.minimum_segment_size
        );
      },
      false,
    );
    return hasParameters;
  };

  const handleSelectedDatasets = (event, datasetId: number) => {
    setSelectedDatasets(prevSelected =>
      prevSelected.includes(datasetId)
        ? prevSelected.filter(id => id !== datasetId)
        : [...prevSelected, datasetId],
    );
  };

  const deleteDatasetByCheck = async () => {
    try {
      await Promise.all(
        selectedDatasets.map(async (datasetId: number) => {
          await deleteDataset(project.id_project, datasetId);
        }),
      );

      const updatedDatasets = project.datasets.filter(
        dataset => !selectedDatasets.includes(dataset.id_dataset),
      );
      dispatch(
        projectActions.editProject({ ...project, datasets: updatedDatasets }),
      );
      setSelectedDatasets([]);
      setOpenDeleteDatasetModal(false);
    } catch (e) {
      setSnackbarProps({
        message: e.response?.data
          ? e.response.data.message
          : t('common.genericErrorMessage'),
        severity: 'error',
      });
      setOpenSnackbar(true);
    }
  };

  const handleDatasetExecute = async () => {
    try {
      const datasets = project.datasets
        .filter(dataset => selectedDatasets.includes(dataset.id_dataset))
        .map(dataset => {
          const zoom = ATTRIBUTE_ZOOM[dataset.name_attribute];
          return {
            ...dataset,
            zoom: zoom,
          };
        });
      await runDataset(project.id_project, datasets);

      const updatedDatasets = project.datasets.map(dataset =>
        selectedDatasets.includes(dataset.id_dataset)
          ? { ...dataset, state: 'Processing' }
          : dataset,
      );

      dispatch(
        projectActions.editProject({ ...project, datasets: updatedDatasets }),
      );

      setOpenExecuteDatasetModal(true);
    } catch (e) {
      setSnackbarProps({
        message: e.response?.data
          ? e.response.data.message
          : t('common.genericErrorMessage'),
        severity: 'error',
      });
      setOpenSnackbar(true);
    }
  };

  return (
    <div>
      <Accordion
        style={{ marginBottom: '24px' }}
        expanded={expanded === `panel${project.id_project}`}
        onChange={handleChange(`panel${project.id_project}`)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{ width: '100%' }}
          aria-controls={`panel${project.id_project}-content`}
          id={`panel${project.id_project}-header`}
        >
          <div style={{ alignSelf: 'center' }}>
            <Checkbox
              checked={selectedProjects.includes(project.id_project)}
              onChange={() => {
                handleSelectedProjects(project.id_project);
              }}
              onClick={event => event.stopPropagation()}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
          <div>
            <Typography variant="h6">{project.name_project}</Typography>

            <Typography sx={{ color: 'text.secondary' }}>
              {t('common.creation_date')}: {project.creation_date}{' '}
              {project.last_execution_date && (
                <>
                  {t('common.last_execution_date')}:{' '}
                  {project.last_execution_date}
                </>
              )}{' '}
            </Typography>

            <Typography>{project.description}</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
            textColor="secondary"
            variant="fullWidth"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#7024d1',
              },
            }}
          >
            <Tab value="stats" label={t('projects.stats')} />
            <Tab value="datasets" label="Datasets" />
            <Tab value="comments" label={t('common.comments')} />
          </Tabs>
          {tabValue === 'stats' && (
            <div style={{ padding: '20px' }}>
              <TableC
                rows={[
                  {
                    idProject: project.id_project,
                    state: project.state,
                    area: formatAreaToKm2(project.area),
                    downloadAoi: true,
                  },
                ]}
                header={[
                  t('common.status'),
                  t('projects.headerArea'),
                  t('projects.headerAOI'),
                ]}
                keys={['state', 'area', 'downloadAoi']}
                downloadActive={downloadActive}
                handleDownloadAoiClick={(fileFormat: string) =>
                  handleDownloadAoiClick(project, fileFormat)
                }
                expanded={expanded}
              />
            </div>
          )}
          {tabValue === 'datasets' && (
            <div style={{ padding: '20px' }}>
              <TableC
                rows={project.datasets}
                header={[
                  '',
                  t('projects.headerCreationDate'),
                  t('projects.headerFileName'),
                  t('common.status'),
                  t('projects.headerImageDate'),
                  t('projects.inputImage'),
                ]}
                keys={[
                  'checkbox',
                  'creation_date',
                  'dataset_name',
                  'state',
                  'image_date',
                  'inputImage',
                ]}
                handleChangeCheck={handleSelectedDatasets}
                handleDownloadAoiClick={(fileFormat: string) =>
                  handleDownloadAoiClick(project, fileFormat)
                }
                showExtraParameters={datasetsWithParameters()}
                expanded={expanded}
              />
            </div>
          )}
          {tabValue === 'comments' && (
            <div style={{ padding: '20px' }}>
              <TableC
                rows={project.comments}
                header={['Comment', 'Modified At']}
                keys={['comment', 'modified_at']}
                updateProject={newProject =>
                  dispatch(projectActions.editProject(newProject))
                }
                setSnackbarProps={setSnackbarProps}
                setOpenSnackbar={setOpenSnackbar}
                expanded={expanded}
              />
              <ButtonCustom
                label={t('projects.addComment')}
                className="buttonStyleColor"
                variant="contained"
                handleClick={() => setOpenModalAddComment(true)}
                data={project.id_project}
                tooltip={t('projects.addComment')}
              />
            </div>
          )}

          <div className="container_button">
            <ButtonCustom
              label={t('common.editButton')}
              className="buttonStyleColor"
              variant="contained"
              handleClick={() => setOpenModalEditProject(true)}
              data={project.id_project}
              tooltip={t('projects.editTooltip')}
            />
            <ButtonCustom
              label={t('common.deleteButton')}
              className="buttonStyleColor"
              variant="contained"
              handleClick={() => setOpenDeleteDatasetModal(true)}
              data={project.id_project}
              disabled={selectedDatasets.length === 0}
              tooltip={t('projects.deleteDatasetTooltip')}
            />
            <ButtonCustom
              label={t('common.executeButton')}
              className="buttonStyleColor"
              variant="contained"
              handleClick={handleDatasetExecute}
              disabled={selectedDatasets.length === 0}
              tooltip={t('projects.executeDatasetTooltip')}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <EditProjectModal
        open={openModalEditProject}
        setOpen={setOpenModalEditProject}
        selectedProject={project}
        listDataset={project.datasets}
        updateListDataset={(newDataset: Dataset) =>
          dispatch(
            projectActions.editProject({
              ...project,
              datasets: project.datasets.map(dataset =>
                dataset.id_dataset === newDataset.id_dataset
                  ? newDataset
                  : dataset,
              ),
            }),
          )
        }
        updateProject={(newProject: ProjectMessage) =>
          dispatch(projectActions.editProject(newProject))
        }
      />

      <ModalCustom
        open={openModalAddComment}
        setOpen={setOpenModalAddComment}
        content={
          <Stack spacing={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add comment
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              maxRows={4}
              onClick={event => {
                event.stopPropagation();
              }}
              sx={{
                '& .MuiInputBase-input': {
                  padding: '5px',
                  fontSize: '14px',
                },
                pointerEvents: 'auto',
              }}
              value={newComment}
              onChange={event => setNewComment(event.target.value)}
            />
          </Stack>
        }
        accept={handleAddComment}
        labelOK={'common.addButton'}
      />

      <CustomSnackbar
        snackbarProps={snackbarProps}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />

      <ModalCustom
        open={openDeleteDatasetModal}
        setOpen={setOpenDeleteDatasetModal}
        content={
          <>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t('projects.deleteDatasetConfirmation')}
            </Typography>
          </>
        }
        accept={deleteDatasetByCheck}
      />

      <ModalCustom
        open={openExecuteDatasetModal}
        setOpen={setOpenExecuteDatasetModal}
        content={
          <div>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t('projects.successExecuteProject')}
            </Typography>
          </div>
        }
        hasButton={false}
      />
    </div>
  );
};

export default ProjectCard;
