export const formatAreaToKm2 = (area: number): string => {
  const areaInKm2 = area / 1_000_000;

  const formattedArea = areaInKm2.toLocaleString("es-ES", {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });

  return `${formattedArea} (km²)`;
};
