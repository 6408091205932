export const localStorageKeys = {
  ACCESS_TOKEN: "access_token",
};

export const apiPaths = {
  project: "project",
  dataset: "dataset",
  files: "files",
  aoi: "aoi",
  algorithm: "algorithm",
  algorithmAttribute: "algorithm_attribute",
  algorithmAsset: "algorithm_asset",
  projectAlgorithm: "project_algorithm",
  user: "user",
  arcgis: "arcgis"
};
