import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import { Button, TextField, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import debounce from 'lodash/debounce';
import useStyles from '../../theme/styles';
import { GOOGLE_MAPS_API_KEY } from '../../config/env';
import './style.scss';

const SearchMap = ({ changeViewport }) => {
  const [openSearchPanel, setOpenSearchPanel] = useState(false);
  const [inputValue, setInputValue] = useState(undefined);
  const autoCompleteRef = useRef(null);

  const classes = useStyles();
  const { t } = useTranslation();

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: GOOGLE_MAPS_API_KEY,
  });

  const debouncedGetPlacePredictions = useCallback(
    debounce(input => getPlacePredictions({ input }), 1000), // Adjust the delay as needed
    [],
  );

  useEffect(() => {
    if (openSearchPanel) {
      autoCompleteRef.current?.focus();
    }
  }, [openSearchPanel]);

  return (
    <div className={classes.flexRow}>
      <Tooltip title={t('common.searchInput')}>
        <Button
          className={`widget-btn ${classes.btn} ${openSearchPanel ? classes.btnOpen : classes.btnNoOpen}`}
          variant="contained"
          startIcon={<SearchIcon className={classes.btn_search} />}
          onClick={() => setOpenSearchPanel(!openSearchPanel)}
        />
      </Tooltip>
      {openSearchPanel && (
        <Autocomplete
          slotProps={{ paper: { style: { marginTop: 8 } } }}
          loading={isPlacePredictionsLoading}
          className={classes.containerSearch}
          value={inputValue}
          options={placePredictions}
          getOptionLabel={item => item.description}
          size="small"
          sx={{
            '.MuiAutocomplete-inputRoot': {
              padding: '0px',
              height: '34px',
              fontSize: 12,
              '&::before': {
                content: 'none',
              },
              '&::after': {
                content: 'none',
              },
            },
          }}
          ListboxProps={{
            style: {
              fontSize: 12,
            },
          }}
          style={{
            width: 230,
            height: 34,
            borderRadius: '0px 4px 4px 0px',
            fontSize: 12,
          }}
          filterOptions={options => options}
          onChange={(_, newValue) => {
            setInputValue(newValue);
            if (newValue) {
              placesService.getDetails(
                {
                  placeId: newValue.place_id,
                },
                placeDetails => {
                  changeViewport([
                    placeDetails.geometry.location.lng(),
                    placeDetails.geometry.location.lat(),
                  ]);
                },
              );
            } else {
              changeViewport([]);
            }
          }}
          onInputChange={(_, newValue) => {
            if (newValue) {
              debouncedGetPlacePredictions(newValue);
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              inputRef={autoCompleteRef}
              placeholder={`${t('common.searchInput')}...`}
              disabled={isPlacePredictionsLoading}
              variant="filled"
              size="small"
              style={{ height: 34 }}
              fullWidth
            />
          )}
        />
      )}
    </div>
  );
};

SearchMap.propTypes = {
  changeViewport: PropTypes.func,
};

export default React.memo(SearchMap);
