import { createSelector } from "@reduxjs/toolkit";

import { initialState } from ".";
import { RootState } from "../store/types/rootState";
import { initialProjectsState } from "./projects";
import { initialDashboardState } from "./dashboard";

export const userSelector = createSelector([(state: RootState) => state?.app || initialState], (app) => app.user);

export const openPanelLeftSelector = createSelector(
  [(state: RootState) => state?.app || initialState],
  (app) => app.openPanelLeft,
);

export const openMapPanelSelector = createSelector(
  [(state: RootState) => state?.app || initialState],
  (app) => app.openMapPanel,
);

export const projectsSelector = createSelector(
  [(state: RootState) => state?.projects || initialProjectsState],
  projects => projects,
);

export const dashboardProjects = createSelector(
  [(state: RootState) => state?.dashboard || initialDashboardState],
  dashboard => dashboard,
);
